<template>
<dev id="main" class="h-screen ">
    <Navigation active="home" />
    <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />
    <div id="resetPW" class=' h-screen flex items-center bg-white opacity-75'>
        <div class="flex mx-auto w-full md:w-2/3 lg:w-1/3 h-auto py-8 border bg-gray-50 items-center rounded-lg relative">
            <div class=" flex flex-col mx-auto  h-full justify-center w-5/6">
            <div @click="backto()" class="absolute right-4 top-4 border cursor-pointer flex rounded-lg z-50">
                    <img class='h-5 w-5 md:h-8 md:w-8' src="../assets/closebutton.svg" />
                </div>
                <h1 class="text-center font-semibold text-base md:text-lg 2xl:text-2xl text-gray-700">Reset Password</h1>
                <form autocomplete="off" @submit.prevent="resetpassword()">
                    <div class="flex flex-col w-full gap-2 my-2 relative">
                        <label for="newpassword">New Password</label>
                        <div class='relative'>
                            <input id='newpassword' @keyup="colorCode('coloralertsreset','newpassword')" required v-model="newpasswords" type="password" placeholder="New Password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" class="relative w-full border border-gray-300 rounded-md">
                            <img v-if="resetpasswordNew=='password'" src="../assets/eyeslash.svg" @click="hideShow('newpassword')" alt="" class="absolute cursor-pointer inline-block " style="margin-left: -30px;margin-top: 10px; ">
                            <img v-else src="../assets/eye.svg" @click="hideShow('newpassword')" alt="" class="absolute cursor-pointer inline-block " style="margin-left: -30px;margin-top: 10px; ">
                            <img id="exclamation" src="../assets/exclamationMark.svg" @click="clickss=!clickss" alt="" class="absolute -right-5 bottom-2 cursor-pointer inline-block z-30 " style="margin-right: 1px; ">
                        </div>
                        <div id='keysforpassword' class='absolute hidden w-full bg-gray-100 z-50 border rounded-lg px-4 py-4 text-sm lg:text-base text-gray-700'>
                            <p> Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters Your password can’t be too similar to your other personal information.</p>
                        </div>
                    </div>
                    <ul id='coloralertsreset' style="display:none;">
                        <div class=' flex gap-1'>
                            <li class='w-3/12 cursor-pointer  border-b-8 inline-block border-red-600'></li>
                            <li class='w-3/12 cursor-pointer  border-b-8 border-red-600'></li>
                            <li class='w-3/12 cursor-pointer  border-b-8 border-red-600'></li>
                            <li class='w-3/12 cursor-pointer  border-b-8 border-red-600'></li>
                        </div>
                    </ul>
                    <div class="flex flex-col w-full gap-2 my-2">
                        <label for="confirmpassword">Re-enter Your New Password</label>
                        <div>
                            <input id='confirmpassword' type="password" placeholder="Re-enter Password" v-model="confirmpasswords" required class="relative w-full border border-gray-300 rounded-md" :class="[newpasswords==confirmpasswords ? 'bg-white ' :'bg-red-300']">
                            <img v-if="resetpasswordconfirm=='password'" src="../assets/eyeslash.svg" @click="hideShow('confirmpassword')" alt="" class="absolute cursor-pointer inline-block " style="margin-left: -30px;margin-top: 10px; ">
                            <img v-else src="../assets/eye.svg" @click="hideShow('confirmpassword')" alt="" class="absolute cursor-pointer inline-block " style="margin-left: -30px;margin-top: 10px; ">
                        </div>
                        <button class="border w-2/3 mx-auto text-white rounded py-2 text-center gradient-btn mt-4">Change Password</button>
                    </div>
                    <p v-if="this.errorMessage.type != 'null'" class='text-center text-red-500 font-semibold' :class="[this.errorMessage.type=='success' ? 'text-green-700' :'text-red-500']"> {{this.errorMessage.text}} </p>
                </form>
            </div>
        </div>

    </div>

    <div id="changePW" class=' h-screen flex items-center bg-white opacity-75'>

        <div class="flex mx-auto w-full md:w-2/3 lg:w-1/3 h-auto py-8 border bg-gray-50 items-center rounded-lg relative">
            <div class=" flex flex-col mx-auto  h-full justify-center w-5/6 ">
                <div @click="backto()" class="absolute right-4 top-4 border cursor-pointer flex rounded-lg z-50">
                    <img class='h-5 w-5 md:h-8 md:w-8' src="../assets/closebutton.svg" />
                </div>
                <h1 class="text-center font-semibold text-base md:text-lg 2xl:text-2xl text-gray-700">Change Password </h1>
                <form autocomplete="off" @submit.prevent="changepassword()">
                    <div class="flex flex-col w-full gap-2 my-2 ">
                        <label for="oldpassword">Old Password</label>
                        <div>
                            <input id='oldpassword' type="password" required placeholder="New Password" class="relative w-full border border-gray-300 rounded-md">
                            <img v-if="passwordOld=='password'" src="../assets/eyeslash.svg" @click="hideShow('oldpassword')" alt="" class="absolute cursor-pointer inline-block " style="margin-left: -30px;margin-top: 10px; ">
                            <img v-else src="../assets/eye.svg" @click="hideShow('oldpassword')" alt="" class="absolute cursor-pointer inline-block " style="margin-left: -30px;margin-top: 10px; ">
                        </div>
                    </div>
                    <div class="flex flex-col w-full gap-2 my-2 relative">
                        <label for="oldtoNew">New Password</label>
                        <div class='relative'>
                            <input id='oldtoNew' @keyup="colorCode('coloralerts','oldtoNew')" required type="password" v-model="newpasswords" class="relative w-full border border-gray-300 rounded-md" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" placeholder="Re-enter Password">
                            <img v-if="passwordnew=='password'" src="../assets/eyeslash.svg" @click="hideShow('oldtoNew')" alt="" class="absolute cursor-pointer inline-block " style="margin-left: -30px;margin-top: 10px; ">
                            <img v-else src="../assets/eye.svg" @click="hideShow('oldtoNew')" alt="" class="absolute cursor-pointer inline-block " style="margin-left: -30px;margin-top: 10px; ">
                            <img id="exclamationChange" src="../assets/exclamationMark.svg" @click="clickss=!clickss" alt="" class="absolute -right-5 bottom-2 cursor-pointer inline-block z-30 " style="margin-right: 1px; ">
                        </div>
                        <div id='keysforpasswordChange' class='absolute hidden w-full bg-gray-100 z-50 border rounded-lg px-4 py-4 text-sm lg:text-base text-gray-700'>
                            <p> Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters Your password can’t be too similar to your other personal information.</p>
                        </div>
                    </div>
                    <ul id='coloralerts' style="display:none;">
                        <div class=' flex gap-1'>
                            <li class='w-3/12 cursor-pointer  border-b-8 inline-block border-red-600'></li>
                            <li class='w-3/12 cursor-pointer  border-b-8 border-red-600'></li>
                            <li class='w-3/12 cursor-pointer  border-b-8 border-red-600'></li>
                            <li class='w-3/12 cursor-pointer  border-b-8 border-red-600'></li>
                        </div>
                    </ul>
                    <div class="flex flex-col w-full gap-2 my-2">
                        <label for="changeconfirm">Re-enter Your New Password</label>
                        <div>
                            <input id='changeconfirm' type="password" required class="relative w-full border border-gray-300 rounded-md" v-model="confirmpasswords" placeholder="Re-enter Password" :class="[newpasswords==confirmpasswords ? 'bg-white' :'bg-red-300']">
                            <img v-if="passwordconfirm=='password'" src="../assets/eyeslash.svg" @click="hideShow('changeconfirm')" alt="" class="absolute cursor-pointer inline-block " style="margin-left: -30px;margin-top: 10px; ">
                            <img v-else src="../assets/eye.svg" @click="hideShow('changeconfirm')" alt="" class="absolute cursor-pointer inline-block " style="margin-left: -30px;margin-top: 10px; ">
                        </div>
                        <button type="submit" class="border w-2/3 mx-auto text-white rounded py-2 text-center gradient-btn mt-4">Change Password</button>
                    </div>
                    <p v-if="this.errorMessage.type != 'null'" class='text-center text-red-500 font-semibold' :class="[this.errorMessage.type=='success' ? 'text-green-700' :'text-red-500']"> {{this.errorMessage.text}} </p>
                </form>
            </div>
        </div>

    </div>

</dev>
</template>

<script>
import Navigation from "@/components/Navigation.vue"
import axios from "axios"
import {
    mapGetters
} from "vuex";
import FlashMessage from "@/components/flashMessage.vue";
export default {
    components: {
        Navigation,
        FlashMessage,
    },
    data() {
        return {
            messages: {
                text: "",
                type: 'null',
            },
            colorval: 0,
            newpasswords: "",
            confirmpasswords: "",
            errorMessage: {
                text: "",
                type: 'null',
            },
            passwordOld: "password",
            passwordnew: "password",
            passwordconfirm: "password",
            resetpasswordNew: "password",
            resetpasswordconfirm: "password",

        }
    },
    computed: mapGetters(["userInfo"]),
    props: ["keyword", "keyval"],
    mounted() {

        document.getElementById("exclamation").addEventListener("mouseover", function () {
            document.getElementById("keysforpassword").style.display = "block";
        })
        document.getElementById("exclamation").addEventListener("mouseout", function () {
            document.getElementById("keysforpassword").style.display = "none";
        })
        document.getElementById("exclamationChange").addEventListener("mouseover", function () {
            document.getElementById("keysforpasswordChange").style.display = "block";
        })
        document.getElementById("exclamationChange").addEventListener("mouseout", function () {
            document.getElementById("keysforpasswordChange").style.display = "none";
        })
        console.log(this.keyword)
        if (this.keyword == "reset") {
            // console.log("reset")
            document.querySelector("#changePW").style.display="none"
        } else if (this.keyword == "change") {
            // console.log("Change")
            document.querySelector("#resetPW").style.display="none"
        } else {
            this.$router.push({
                name: "PageNotFound"
            })
        }

        var PWD = document.getElementById("oldtoNew");

        PWD.onfocus = function () {
            document.getElementById("coloralerts").style.display = "block";
        }

        PWD.onblur = function () {
            document.getElementById("coloralerts").style.display = "none";
        }
        var PWDreset = document.getElementById("newpassword");

        PWDreset.onfocus = function () {
            document.getElementById("coloralertsreset").style.display = "block";
        }

        PWDreset.onblur = function () {
            document.getElementById("coloralertsreset").style.display = "none";
        }

    },
    methods: {

        backto(){
            this.$router.push({
                name: "Home"
            })
        },

        colorCode(data, inputclass) {
            document.getElementById(`${inputclass}`).value

            var PWD = document.getElementById(`${inputclass}`);
            var lowerCaseLetters = /[a-z]/g;
            var upperCaseLetters = /[A-Z]/g;
            var numbers = /[0-9]/g;
            document.getElementById(`${data}`).lastChild.childNodes.forEach(el => {
                el.style["border-color"] = 'red'
            })

            if ((PWD.value.match(lowerCaseLetters) || PWD.value.match(upperCaseLetters) || PWD.value.match(numbers) || PWD.value.length >= 8) && PWD.value.length != 0) {
                document.getElementById(`${data}`).lastChild.childNodes[0].style["border-color"] = 'green'

                if ((PWD.value.match(lowerCaseLetters) && PWD.value.match(upperCaseLetters)) ||
                    (PWD.value.match(lowerCaseLetters) && PWD.value.match(numbers)) ||
                    (PWD.value.match(lowerCaseLetters) && PWD.value.length >= 8) ||
                    (PWD.value.match(upperCaseLetters) && PWD.value.match(numbers)) ||
                    (PWD.value.match(upperCaseLetters) && PWD.value.length >= 8) ||
                    (PWD.value.match(numbers) && PWD.value.length >= 8)) {

                    document.getElementById(`${data}`).lastChild.childNodes[1].style["border-color"] = 'green'

                    if ((PWD.value.match(lowerCaseLetters) && PWD.value.match(upperCaseLetters) && PWD.value.match(numbers)) ||
                        (PWD.value.match(lowerCaseLetters) && PWD.value.match(numbers) && PWD.value.length >= 8) ||
                        (PWD.value.match(lowerCaseLetters) && PWD.value.length >= 8 && PWD.value.match(upperCaseLetters)) ||
                        (PWD.value.match(upperCaseLetters) && PWD.value.match(numbers) && PWD.value.length >= 8)

                    ) {
                        document.getElementById(`${data}`).lastChild.childNodes[2].style["border-color"] = 'green'
                        if (PWD.value.match(lowerCaseLetters) && PWD.value.match(upperCaseLetters) && PWD.value.match(numbers) && PWD.value.length >= 8) {
                            document.getElementById(`${data}`).lastChild.childNodes[3].style["border-color"] = 'green'
                        } else {
                            document.getElementById(`${data}`).lastChild.childNodes[3].style["border-color"] = 'red'
                        }
                    } else {
                        document.getElementById(`${data}`).lastChild.childNodes[2].style["border-color"] = 'red'

                    }
                } else {
                    document.getElementById(`${data}`).lastChild.childNodes[1].style["border-color"] = 'red'
                }
            } else {

                document.getElementById(`${data}`).lastChild.childNodes[0].style["border-color"] = 'red'
            }

        },
        hideShow(id) {

            const passwordField = document.querySelector(`#${id}`)

            passwordField.getAttribute('type') === 'password' ? passwordField.setAttribute('type', 'text') : passwordField.setAttribute('type', 'password')
            switch (id) {
                case "oldpassword":
                    this.passwordOld = passwordField.getAttribute('type')
                    break;
                case "oldtoNew":
                    this.passwordnew = passwordField.getAttribute('type')
                    break;
                case "changeconfirm":
                    this.passwordconfirm = passwordField.getAttribute('type')
                    break;
                case "newpassword":
                    this.resetpasswordNew = passwordField.getAttribute('type')
                    break;
                case "confirmpassword":
                    this.resetpasswordconfirm = passwordField.getAttribute('type')
                    break;
            }

        },

        async resetpassword() {
            let newpassword = document.querySelector("#newpassword").value
            let confirmpassword = document.querySelector("#confirmpassword").value
            if (newpassword == confirmpassword) {

                const headerVal = {
                    "password": newpassword,
                    "token": this.keyval
                }

                await axios.post(`${process.env.VUE_APP_API}/api/password_reset/confirm/`, headerVal)
                    .then(response => {
                        console.log(response)
                        if (response.data.status = "OK") {
                            this.errorMessage = {
                                text: " Password Changed Successfully",
                                type: 'success',
                            }

                        } else {
                            this.errorMessage = {
                                text: response.data.error,
                                type: 'error',
                            }

                        }
                    })
                    .catch(error => {
                        console.log(error.response.data.detail)
                        if (error.response.data.detail) {
                            this.errorMessage = {
                                text: "Mail Expired ",
                                type: 'error',
                            }
                            setTimeout(() => {
                                this.errorMessage = {
                                    text: " ",
                                    type: '',
                                }
                            }, 1500)
                        }
                    })
                document.querySelector("#newpassword").value = ""
                document.querySelector("#confirmpassword").value = ""
                setTimeout(() => {
                                this.$router.push({name : 'Login'})
                            }, 2000)
            } else {
                this.errorMessage = {
                    text: " Confirm Password Miss Match",
                    type: 'error',
                }
                setTimeout(() => {
                    this.errorMessage = {
                        text: " ",
                        type: '',
                    }
                }, 1500)

            }
        },
        async changepassword() {

            let oldpassWord = document.querySelector("#oldpassword").value
            let newpassword = document.querySelector("#oldtoNew").value
            let confirmpassword = document.querySelector("#changeconfirm").value
            if (newpassword == confirmpassword) {
                var responseMail
                let loginHeader = {
                    "username": this.userInfo.user.email,
                    "password": oldpassWord
                }
                // OldPassword Validations

                await axios.post(`${process.env.VUE_APP_API}/login`, loginHeader)
                    .then(response => {
                        if (response.data.user) {
                            responseMail = response.data.user.email
                        } else {
                            responseMail = "error"

                        }
                    })

                if (responseMail == this.userInfo.user.email) {
                    const headerVal = {
                        "old_password": document.querySelector("#oldpassword").value,
                        "new_password": document.querySelector("#changeconfirm").value
                    }
                    await axios.put(`${process.env.VUE_APP_API}/api/change-password/?Key=${this.userInfo.token_key}`, headerVal)
                        .then(response => {

                            if (response.data.message == 'Password updated successfully') {
                                this.messages = {
                                    text: "Password updated successfully",
                                    type: 'success',
                                }
                            } else {
                                this.messages = {
                                    text: " Event Faild",
                                    type: 'error',
                                }
                            }
                        })
                        .catch(error => {
                            console.log(error)
                        })
                    document.querySelector("#oldpassword").value = ""
                    document.querySelector("#oldtoNew").value = ""
                    document.querySelector("#changeconfirm").value = ""

                    // document.querySelector("#changePW").innerHTML="<h1 >Email Id Changed successfully</h1>"
                    setTimeout(() => {

                        this.$router.push({
                            name: 'Home'
                        })
                    }, 3000)
                } else {
                    this.errorMessage = {
                        text: " Old Password Not Matching",
                        type: 'error',
                    }

                    setTimeout(() => {
                        this.errorMessage = {
                            text: " ",
                            type: '',
                        }
                    }, 1500)
                }
            } else {
                console.log("error")
                this.errorMessage = {
                    text: " Confirm Password Miss Match",
                    type: 'error',
                }
                setTimeout(() => {
                    this.errorMessage = {
                        text: " ",
                        type: '',
                    }
                }, 1500)
            }

        }
    },
}
</script>

<style scoped>
input:focus {
    border: 1px solid rgb(84, 161, 175);
}
</style>
