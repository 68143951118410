<template lang="">
<Navigation />
<FlashMessage v-if="this.messages.text != ''" :message="this.messages" />
<div class="container-default-1209px w-container border py-4 m-4 rounded-md shadow-lg">
    <h3 class="text-center">New webinar</h3>
    <form ref="addwebinarRef" class="flex flex-col gap-4" @submit.prevent='blog_post'>
        <div class="w-full lg:w-8/12">
            <label for="title">Title <span class="text-red-400">*</span></label>
            <input type="text" id="title" class="w-full p-2 block rounded-md text-sm">
        </div>
        <div class="flex flex-col md:flex-row md:justify-arround">
            <div class="w-full lg:w-3/12 pr-2">
                <label for="category" class="flex gap-2 items-center">
                    <div>
                        Category<span class="text-red-400">*</span>
                    </div>
                    <font-awesome-icon @click="is_CategoryPopup=true" class="text-base text-green-500 self-end h-6 w-6 cursor-pointer hover:text-green-800" :icon="['fas','plus']" title="Add Category" />
                </label>
                <select id="category" name="category" class="p-2 block w-full rounded-md text-sm" required>
                    <option v-for="category in categories" :key="category" :value="category.id">
                        {{category.name}}
                    </option>
                </select>
            </div>
            <div class="w-full lg:w-3/12 pl-2">
                <label for="duration">Duration </label>
                <input type="number" min="0" id="duration" class="p-2 w-full rounded-md text-sm" />
            </div>
            <div class="w-full lg:w-3/12 pl-2">
                <label for="eventDate">Event Date </label>
                <input type="datetime-local" min="0" id="eventDate" class="p-2 w-full rounded-md text-sm" />
            </div>
            <div class="w-full lg:w-3/12 pl-2">
                <label for="regDate">Reg-last Date </label>
                <input type="datetime-local" id="regDate" class="p-2 w-full rounded-md text-sm" />
            </div>
        </div>
        <div class="">
            <label for="shortDesc">
                Short Description
            </label>
            <textarea id="shortDesc" maxlength="200" required class="border border-gray-300 rounded-md w-full p-2"></textarea>
        </div>
        <div class="">
            <label for="Content">
                Content<span class="text-red-400">*</span>
            </label>
            <MarkdownEditor class="rounded-md" />
        </div>
        <div class="">
            <label for="thumbnail">
                Thumbnail <span class="text-gray-300 text-xs "> (600*300 size)</span>
            </label>
            <input id="thumbnail" type="file" accept="image/*" class="border border-gray-300 rounded-md text-sm p-2">
        </div>
        <div class="flex items-center gap-2 p-2">
            <label for='published'>Published</label>
            <input type="checkbox" id="published">
        </div>
        <div class="">
            <button type="submit" class="gradient-btn">Submit</button>
        </div>
    </form>
</div>
<!-- Category Add in Webinar  -->
<div v-if="is_CategoryPopup" class="fixed top-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center" style="z-index:5001">
    <div class="w-5/6 lg:w-2/3 h-1/3 2xl:w-1/3 2xl:h-1/5 bg-white card1 flex flex-col justify-center">
        <div @click="is_CategoryPopup=false" class="absolute right-8 top-8 cursor-pointer">
            <div class='mb-1  rounded-lg flex border cursor-pointer'>
                <div class="px-1 md:px-2 py-1 text-sm md:text-base text-gray-700 hover:text-red-500">Close </div>
                <img class='h-5 w-5 md:h-8 md:w-8' src="../assets/closebutton.svg" />
            </div>
        </div>
        <h4 class="text-gray-700 text-center">Add Category</h4>
        <form class="w-11/12 mx-auto" @submit.prevent="categoryAdd">
            <label for="title">
                Title<span class="text-red-400">*</span>
            </label>
            <input id="title" type="text" required class="border border-gray-300 rounded-md text-sm w-full p-2">
            <button type="submit" class="gradient-btn mt-4">
                Add course
            </button>
        </form>
    </div>
</div>
<Footer />
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";
import MarkdownEditor from "@/components/MarkdownEditor2.vue";
import axios from "axios";
import {
    mapGetters
} from "vuex";
import FlashMessage from "@/components/flashMessage.vue";
export default {
    data() {
        return {
            categories: '',
            messages: {
                text: "",
                type: 'null',
            },
            is_CategoryPopup: false
        }
    },
    computed: mapGetters(["userInfo"]),
    components: {
        Navigation,
        Footer,
        MarkdownEditor,
        FlashMessage
    },

    created() {

        this.categoryget()
    },
    methods: {
        // add the new Category in Webinar
        categoryAdd(el) {
            let bodyVal = {
                "name": el.target.elements.title.value,
            }

            axios.post(`${process.env.VUE_APP_API}/webinar-category/post/`, bodyVal)
                .then(response => {
                    if (response.data.message == "Webinar Category added successfully") {
                        this.messages = {
                            text: "Category Added successfully",
                            type: 'success',
                        }
                        setTimeout(() => {
                            this.messages = {
                                text: "",
                                type: 'null',
                            }
                            this.is_CategoryPopup = false
                            this.categoryget()
                        }, 1500);
                    } else {
                        this.messages = {
                            text: response.data.message,
                            type: 'error',
                        }
                        setTimeout(() => {
                            this.messages = {
                                text: "",
                                type: 'null',
                            }
                        }, 2000)
                    }
                })
        },
        categoryget() {
            axios.get(`${process.env.VUE_APP_API}/webinar-category/list/`)
                .then(response => {
                    this.categories = response.data.data
                })
        },
        blog_post(el) {
            console.log(el.target.elements.eventDate.value)
            console.log(el.target.elements.regDate.value)
            console.log(el.target.elements.thumbnail.files, 'file list')
            let HeaderVal = new FormData();
            HeaderVal.append("title", el.target.elements.title.value)
            if (el.target.elements.thumbnail.files.length > 0) {
                HeaderVal.append("thumbnail", el.target.elements.thumbnail.files[0])
            }
            HeaderVal.append("duration", el.target.elements.duration.value)
            HeaderVal.append("event_date", el.target.elements.eventDate.value)
            HeaderVal.append("reg_lastDate", el.target.elements.regDate.value)
            HeaderVal.append("short_description", el.target.elements.shortDesc.value)
            HeaderVal.append("published", el.target.elements.published.checked)
            HeaderVal.append("content", el.target.elements[6].value)
            HeaderVal.append("instructor_id", this.userInfo.user.id)
            HeaderVal.append("category_id", parseInt(el.target.elements.category.value))

            axios.post(`${process.env.VUE_APP_API}/webinar-details/post/`, HeaderVal, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then(response => {
                    if (response.data.message == "Webinar added successfully") {
                        this.messages = {
                            text: "webinar Added successfully",
                            type: 'success',
                        }

                        setTimeout(() => {
                            this.messages = {
                                text: "",
                                type: 'null',
                            }
                            this.$refs.addwebinarRef.reset();
                            this.$router.push({
                                name: 'WebinarListInstructor'
                            });
                        }, 1500)

                    } else {
                        this.messages = {
                            text: " Event Faild",
                            type: 'error',
                        }

                        setTimeout(() => {
                            this.messages = {
                                text: "",
                                type: 'null',
                            }
                        }, 1500)
                    }

                })

        }
    }
}
</script>

<style lang="">
    
</style>
