import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import login from "./modules/login";

export default createStore({
  modules: {
    login
  },
  plugins: [createPersistedState()],
});
