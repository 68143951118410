<template>
  <Navigation />
  <!-- <div class="flex h-10 py-1  justify-center border border-teal-500 bg-teal-50">
      
      <h1  class=" text-lg lg:text-2xl font-semibold capitalize">{{course_name}} </h1>
  </div> -->

  <router-view :event="instructordashboard" />

</template>

<script>
import Navigation from '../../components/Navigation.vue'
export default {
  components: { Navigation },
props:["course_id","course_name"]
}
</script>

<style>

</style>
