<template lang="">
<!-- Blog List for instructor -->
<div class="min-h-screen w-full">
    <Navigation />
    <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />
    <confirmPopup v-if='popupwindow==true' :title="titlepass" @cancelClick="popupwindow=false" @confirmClick="deleteblog(idpass)" />
    <div class="relative">
        <div data-w-id="0b097d78-b776-9e09-a4b6-8ab657637224" style="" class="section learning-center wf-section">
            <div data-w-id="0b097d78-b776-9e09-a4b6-8ab657637225" style="" class="container-small-462px text-center w-container">
                <h2>Blogs</h2>
                <p>Discover great content to grow your skills in Design, Development and Marketing.</p>
            </div>
            <div class="container-default-1209px w-container">
                <div class="w-dyn-list">
                    <div v-if="blog_list != ''" class="learning-center-section-grid w-dyn-items  ">
                        <div v-for="el in blog_list" :key="el" data-w-id="0b097d78-b776-9e09-a4b6-8ab657637241" style="height:528px" role="listitem" class="post-card w-dyn-item my-2 mx-auto card">
                            <div class="w-80 md:w-96 " style="height:528px">
                                <a href="#" class="card-image-link post w-inline-block">
                                    <div style="overflow: hidden;width:396px;height:208px" class="image course w-80 md:w-96 relative">
                                        <img v-if="el.thumbnail != null" :src="el.thumbnail" alt="" style="overflow: hidden;width:396px;height:208px" class="image course w-80 md:w-96">
                                        <img v-else src="https://assets.website-files.com/5f52d24bbe17c5455b1c6b7f/5f52d24bbe17c5ea4e1c6cd8_8%20Great%20Design%20Trends%20That%20Are%20Coming%20Back-thubmnail-image-academy-template.jpg" alt="" style="overflow: hidden;width:396px;height:208px" class="image course w-80 md:w-96">
                                        <div class="absolute right-0 bottom-0 bg-white w-14 h-10 flex justify-center px-1  items-end" style="border-top-left-radius: 3rem;" @click="thumbnailUpdate=true;this.blog_id=el.id">
                                            <img id="EditImg" src="../assets/edit.svg" alt="" class=" text-whiteh-6 w-8 font-bold pl-1 cursor-pointer" title="Edit Thumbnail">
                                        </div>
                                    </div>
                                </a>
                                <div class="card-content">
                                    <a href="#" class="card-title-link w-inline-block">
                                        <h3 @click="previewpage(el)" class="text-xl post-card">{{el.title}}</h3>
                                    </a>
                                    <p> Created @ {{ new Date(el.create_date).toLocaleDateString('en-IN', {day: 'numeric',month: 'long',year: 'numeric'}) }}</p>
                                    <div class="divider card-post"></div>
                                    <div data-w-id="6288b6c0-964c-5adc-f34f-d4b8510c1e2f" href="#" class=" card-post-author-wrapper w-inline-block flex justify-between">
                                        <div class="card-post-author-wrapper w-inline-block gap-2">
                                            <!-- <img src="https://assets.website-files.com/5f52d24bbe17c5455b1c6b7f/5f52d24bbe17c5a98f1c6c3f_norma-hicks-academy.jpg" alt="" class="card-post-author-picture"> -->
                                            <div class="card-post-author-name capitalize">
                                                <button class="border border-gray-200 px-2 rounded flex gap-1 text-sm items-center py-1 hover:bg-gray-100 " @click="getdetails(el)">
                                                    <img src="../assets/edit.svg" alt="" class="h-6 w-6 cursor-pointer" title="Edit Course">
                                                    Edit section
                                                </button>
                                            </div>
                                            <button class="border border-red-400 px-2 rounded flex gap-1 text-sm items-center py-1 hover:bg-red-100 " @click="titlepass=el.title;popupwindow=true;idpass=el.id">
                                                <font-awesome-icon class="text-base text-red-400 self-center h-6 w-6" :icon="['fas','trash-alt']" />
                                                Delete
                                            </button>
                                        </div>
                                        <div>
                                            {{el.likes.length}} Likes
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <a href="#" class="category-wrapper w-inline-block ">
                                <div class="category-text">{{el.category_id.name}}</div>
                            </a>
                        </div>

                    </div>
                    <div v-else class="card empty-state w-dyn-empty" v-for="el in blog_list" :key="el">
                        <div class="empty-state-text">There are no Articles available yet.</div>
                    </div>
                </div>
                <div data-w-id="0b097d78-b776-9e09-a4b6-8ab657637245" style="" class="flex flex-col md:flex-row justify-center gap-4">
                    <router-link :to="{name:'AddBlogFrom'}" class="button-secondary w-button ">Add Blog</router-link>
                </div>
            </div>
        </div>
        <div v-if="is_popUp" class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 overflow-y-auto" style="z-index:1000">
            <div class="container-default-1209px w-container border py-4 m-4 card relative">
                <h3 class="text-center">Update Blog</h3>
                <img src="https://img.icons8.com/cotton/46/undefined/delete-sign--v2.png" @click="is_popUp=false" class="absolute top-6 right-6  z-50 cursor-pointer" />
                <form ref="UpdateBlogRef" @submit.prevent='UpdateBlog' class="flex flex-col gap-4 ">
                    <div class="w-full lg:w-8/12">
                        <label for="title">Title <span class="text-red-400">*</span></label>
                        <input type="text" id="title" class="w-full p-2 block rounded-md text-sm" v-model='editVal.title'>
                    </div>
                    <div class="flex flex-col md:flex-row md:justify-arround">
                        <div class="w-full lg:w-1/3 pr-2">
                            <label for="category">
                                Category<span class="text-red-400">*</span>
                            </label>
                            <select id="category" name="category" class="p-2 block w-full rounded-md text-sm" required v-model='editVal.category_id.id'>
                                <option v-for="category in categories" :key="category" :value="category.id">
                                    {{category.name}}
                                </option>
                            </select>
                        </div>
                        <div class="w-full lg:w-1/3 pl-2">
                            <label for="version">Version <span class="text-red-400">*</span></label>
                            <input type="number" min="0" id="version" v-model='editVal.version' class="p-2 w-full rounded-md text-sm" />
                        </div>
                    </div>
                    <div class="">
                        <label for="Content">
                            Content<span class="text-red-400">*</span>
                        </label>
                        <MarkdownEditor class="rounded-md" :markvalues='editVal.content' />
                    </div>
                    <!-- <div class="">
                        <label for="thumbnail">
                            Thumbnail
                        </label>
                        <input id="thumbnail" type="file" accept="image/*" class="border border-gray-300 rounded-md text-sm p-2" >
                    </div> -->
                    <div class="flex items-center gap-2 p-2">
                        <label for='published'>Published</label>
                        <input type="checkbox" id="published" v-model='editVal.published'>
                    </div>
                    <div class="">
                        <button type="submit" class="gradient-btn">Submit</button>
                    </div>
                </form>
            </div>
        </div>

        <!-- Thumnail Update -->
        <div v-if="thumbnailUpdate" class="absolute top-0 w-full h-full min-h-screen bg-white flex items-center justify-center">
            <div class="center h-full flex justify-center items-center ">
                <div class="form-input ">
                    <div class="preview w-full h-full relative">
                        <img id="imgPreview" class="hidden" style="width:100%;height:300px;max-width:600px;">
                        <img v-if="submitting" src="../assets/closebutton.svg" alt="" class="w-7 h-7 absolute right-0 top-0 cursor-pointer" @click="imgreset()">
                    </div>
                    <form @submit.prevent="thumbnailUpdatefN" ref="imguploadref" class="w-full flex flex-col relative">
                        <label v-if="submitting==false" for="imgFileupload" class="gradient-btn px-2 ">Upload Image</label>
                        <input type="file" id="imgFileupload" accept=".jpg, .jpeg, .png, .webp" class="hidden" @change="showPreview();">
                        <button v-if="submitting" type="submit" class="gradient-btn mx-auto w-32 mt-2">Submit</button>
                        <img v-if="submitting==false" src="../assets/closebutton.svg" alt="" class="w-7 h-7 absolute -right-12 -top-12 cursor-pointer" @click="thumbnailUpdate=false">
                    </form>
                </div>
            </div>
        </div>
    </div>
    <Footer />

</div>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";
import MarkdownEditor from "@/components/MarkdownEditor2.vue";
import FlashMessage from "@/components/flashMessage.vue";
import confirmPopup from "@/components/confirmPopup.vue";
import axios from "axios";
import {
    mapGetters
} from "vuex"
export default {
    data() {
        return {
            blog_list: '',
            editVal: '',
            messages: {
                text: "",
                type: 'null'
            },
            is_popUp: false,
            categories: '',
            titlepass: '',
            popupwindow: false,
            idpass: '',
            thumbnailUpdate: false,
            submitting: false,
            blog_id: ''
        }
    },
    computed: mapGetters(['userInfo']),
    components: {
        Navigation,
        Footer,
        MarkdownEditor,
        FlashMessage,
        confirmPopup
    },
    created() {
        this.getBlogList()
    },
    methods: {

        async thumbnailUpdatefN(el) {
            let fileVal = el.target.elements["imgFileupload"].files[0]
            let urls = `${process.env.VUE_APP_API}/blog-details/deleted/${this.blog_id}/`;
            let formData = new FormData();
            formData.append("thumbnail", fileVal);

            await axios.patch(urls, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }).then((response) => {
                if (response.data.status == "success") {
                    this.messages = {
                        text: "Image Uploaded successfully",
                        type: 'success',
                    }
                } else {
                    this.messages = {
                        text: " Event Faild",
                        type: 'error',
                    }
                }
            })
            this.$refs.imguploadref.reset();
            this.thumbnailUpdate = false
            this.submitting = false
            setTimeout(async () => {
                this.messages = {
                    text: "",
                    type: 'null',
                }

                await this.getBlogList()
            }, 1500)

        },
        imgreset() {

            this.$refs.imguploadref.reset();
            this.thumbnailUpdate = false
            this.submitting = false
        },
        showPreview() {
            if (event.target.files.length > 0) {
                var src = URL.createObjectURL(event.target.files[0]);
                var preview = document.getElementById("imgPreview");
                preview.src = src;
                preview.style.display = "block";
                this.submitting = true
            }
        },
        getBlogList() {
            axios.get(`${process.env.VUE_APP_API}/blog-details/list/?bolger_id=${this.userInfo.user.id}`)
                .then(response => {
                    if (response.data.message != 'No Blog details available') {
                        this.blog_list = response.data.data
                    } else {
                        this.blog_list = ''
                    }
                })

            axios.get(`${process.env.VUE_APP_API}/blog-category/list/`)
                .then(response => {
                    this.categories = response.data.data
                })
        },
        getdetails(el) {
            this.editVal = el
            this.is_popUp = true

        },
        previewpage(el) {

            this.$router.push({
                name: 'BlogPreview',
                params: {
                    blog_id: el.id
                }
            })
        },
        UpdateBlog(el) {

            let headerVal = {
                'title': this.editVal.title,
                'version': this.editVal.version,
                'published': this.editVal.published,
                'content': el.target.elements[3].value,
                'category_id': this.editVal.category_id.id,

            }
            axios.patch(`${process.env.VUE_APP_API}/blog-details/deleted/${this.editVal.id}/`, headerVal)
                .then(response => {
                    console.log()
                    if (response.status == 200) {
                        console.log('message')
                        this.messages = {
                            text: "Blog Updated successfully",
                            type: 'success',
                        }
                        setTimeout(() => {
                            this.messages = {
                                text: "",
                                type: 'null',
                            }
                            this.is_popUp = false
                            this.getBlogList()
                        }, 1500)
                    } else {
                        this.messages = {
                            text: " Event Faild",
                            type: 'error',
                        }
                        setTimeout(() => {
                            this.messages = {
                                text: "",
                                type: 'null',
                            }
                        }, 1500)
                    }
                })

        },

        deleteblog(el) {
            axios.delete(`${process.env.VUE_APP_API}/blog-details/deleted/${el}/`)
                .then(response => {
                    console.log(response)
                    this.popupwindow = false

                    this.getBlogList()
                })
        }
    }
}
</script>

<style lang="">
    
</style>
