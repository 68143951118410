<template>
  <router-view  />
</template>

<script>
import axios from "axios"

import {mapGetters} from "vuex"
export default {
    data(){
       return {
           organizationdetaill : null,
        
       }
    },
    computed: mapGetters(["userInfo"]),
    components: {
    },
     created() {

        let token_id = this.userInfo.token_key
        axios.get(`${process.env.VUE_APP_API}/organization_detail/?Key=${token_id}`)
        .then(response => {this.organizationdetaill=response.data.data})
        
    }
}
</script>

<style>

</style>
