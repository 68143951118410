<template>
    <Navigation />
<div class='overflow-y-auto h-screen  flex ' style="scrollbar-color:gray white ">
    <internLaftNav actived='Administrators' />
    <div class="flex justify-center w-full">
        <div class="inline-block overflow-x-auto md:overflow-y-auto h-5/6 w-full">

            <table class="leading-normal w-full text-md">
                <thead>
                    <tr class="bg-gray-100 border-b-2 border-teal-700 px-2 lg:px-5 py-2 lg:py-4 
                            font-semibold text-teal-600 tracking-wider">
                        <th class="">
                            Name
                        </th>
                        <th class=" ">
                            Email
                        </th>
                        <th class="">
                            Status
                        </th>

                        <th class=" ">
                            Signed up on
                        </th>
                    </tr>
                </thead>

                <tbody v-for="instructor in adminInfo.data" :key="instructor">
                    <tr>
                        <!-- <td @click="mailEvent(instructor)" class="border border-gray-200">
                            {{instructor.user_name}}
                        </td> -->
                        <td class="border border-gray-200">
                            {{instructor.user_name}}
                        </td>
                        <td class="border border-gray-200">
                            {{instructor.user_email}}
                        </td>
                        <td v-if="instructor.published==true" class=" border border-gray-200 text-center">
                            <span class="bg-green-300 px-2 rounded">Joined</span>
                        </td>
                        <td v-if="instructor.published==false" class=" border border-gray-200 text-center">
                            <span class="bg-red-300 px-2 rounded">Invited</span>
                        </td>

                        <td class="border border-gray-200">
                            <p class="text-center text-gray-900 whitespace-no-wrap">
                                {{ new Date(instructor.user_date_joined).toLocaleString('en-IN', {
                            timeZone: 'Asia/Kolkata',
                            day: 'numeric',
                            month: 'numeric',
                            year: 'numeric'
                        })}}
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div v-if="is_mailevent.status" class='absolute  z-50 bottom-0 right-0 w-full md:w-5/6 lg:w-3/6 h-auto bg-white flex items-center justify-center border'>
        <main class=" bg-white flex flex-col w-full h-full">
            <form ref="mailref" @submit.prevent="sendmail" class="">
                <section class='bg-teal-600 text-white flex '>
                    <div class='w-5/6 px-2 py-1'>New Message </div>
                    <div class='w-1/6 flex gap-1 justify-end px-4 py-1'>
                        <div @click="is_mailevent.status = false" class="absolute  border cursor-pointer flex rounded-lg">
                            <img class='h-6 w-6 ' src="../../assets/closebutton.svg" />
                        </div>
                    </div>
                </section>
                <section class=' '>
                    <div class='mt-2 border-gray-200 px-2'>
                        <label for='to' class=' w-1/6'></label>
                        <input input='email' required class='border-b  w-full pl-1' :value='this.is_mailevent.name' placeholder='To' disabled />
                    </div>
                    <div class='mt-2  border-gray-200 px-2'>
                        <label for='subject' class=' w-1/6'></label>
                        <input input='text' id='subject' required class='border-b  w-full pl-1' placeholder='Subject' />
                    </div>
                    <div class='mt-2 sm:h-4/6 2xl:h-full px-2'>
                        <label for='content' class=' w-1/6'></label>
                        <textarea rows="12" cols="10" id='content' class="border border-gray-300 h-full w-full pl-1 rounded" placeholder='Content'></textarea>
                    </div>
                    <div class='py-2 px-2'>
                        <button type='submit' class='gradient-btn'>Send</button>
                    </div>
                </section>
            </form>
        </main>
    </div>
</div>
</template>

<script>
import axios from "axios"
import {
    mapGetters
} from "vuex";
import Navigation from "@/components/Navigation.vue"
import internLaftNav from "@/views/organization/leftNavIntern.vue"

export default {
    data() {
        return {
            tnxDetails: null,
            adminInfo: null,
            is_mailevent: {
                status: false,
                emailId: "",
                name: ""
            },
        }
    },
    components: {
        Navigation,
        internLaftNav
    },
    computed: mapGetters(["userInfo"]),
    created() {

        axios.get(`${process.env.VUE_APP_API}/instructordetaill/?Key=${this.userInfo.token_key}`)
            .then(response => console.log(this.adminInfo = response.data))
    },
    methods: {
        mailEvent(el) {
            console.log(el)
            this.is_mailevent.status = true
            this.is_mailevent.emailId = el.user_email
            this.is_mailevent.name = el.user_name
        },
    }

}
</script>

<style scoped>
th,
tr,
td,
table {
    padding: 10px;
}
</style>
