<template >
    <div class="min-h-screen">
        <div class="w-4/6 mx-auto mt-5">
        
        <h1 class="text-2xl">Course Lists </h1>
        <div v-for="course in courseInfo.data" :key="course" class="border px-5 w-2/6 py-3 m-2  hover:text-teal-700">
           <router-link :to='{name :"sessionStudent",params:{slug:userInfo.slug_id,course_id:course.coures_id,instructor_id:course.instructor_id }}' >
               {{course.title}}
           </router-link>
        </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import Navigation from "@/components/Navigation.vue"
import { mapGetters } from 'vuex'
export default {
    data(){
        return{
            courseInfo : "",

        }
    },
    components:{
        Navigation,
    },
    computed:mapGetters(["userInfo"]),

    created(){
        
        axios.get(`${process.env.VUE_APP_API}/studentsbuycoursedetails/?Key=${this.userInfo.token_key}`)
        .then(response=>{console.log((this.courseInfo) = response.data)})
    },

}
</script>
<style lang="">
    
</style>