<template lang="">
<div class=" w-full h-screen">
    <!-- <Navigation /> -->
    <!-- <leftNavigationStudent actived='documents' /> -->
    
    <!-- Video Rendering -->
    <!-- <div class=" w-4/6 h-4/6 m-auto">
        <video class='rounded-lg' controls src="https://dev.maggana.com/media/video/toyota_kaizen_clip.mp4" width="1200">
        </video>
    </div> -->

<!-- Vue3 Video render library Component -->

<!--     
  <div class="player-container">
    <vue3-video-player  @play="your_method" src="https://dev.maggana.com/media/video/toyota_kaizen_clip.mp4"></vue3-video-player>
  </div> -->
  
  <!-- <div class="player-container">   -->
    <!-- Video tag is Good compare to other video rendering library -->
    <!-- <video  class='rounded-lg' controls src="https://dev.maggana.com/media/video/toyota_kaizen_clip.mp4" width="1200" />
  </div>
   -->
  <!-- <iframe id='renderVideoLink' src="https://www.youtube.com/embed/tgbNymZ7vqY?autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
  
  <!-- Pdf Render -->
  <!-- <div class="w-full">
   <vue-pdf-embed  :source="source1" />
  </div> -->

<!-- <Quiz/> -->
<!-- <div id="body">
<div class="Stars" style="--rating: 4;" aria-label="Rating of this product is 2.3 out of 5."> </div>
</div> -->
        <ckeditor :editor="editor" v-model="editorData" :config="editorConfig" ></ckeditor>
</div> 
</template>

<script>
import Navigation from "@/components/Navigation.vue"
import leftNavigationStudent from "@/views/studentDashboard/leftnav.vue";
import VuePdfEmbed from 'vue-pdf-embed'
import Quiz from "@/components/Quiz.vue"
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    data() {
    return {
      source1: '/medias/Lean_Thinking_ANZ_Ref_Manual.pdf',
      editor: ClassicEditor,
                editorData: '<p>Content of the editor.</p>',
                editorConfig: {
                    // The configuration of the editor.
                }

    }
  },
    components: {
        Navigation,
        leftNavigationStudent,
        VuePdfEmbed,
        ClassicEditor,
        Quiz,
    },
    mounted() {
      console.log('mahe')
            // Video Duration Check
        
            
            // Check the Video position ====================================================================================> test
                console.log(document.getElementById("renderVideoLink"))
                document.getElementById("renderVideoLink").onended = function() {
                if(this.played.end(0) - this.played.start(0) === this.duration) {
                    console.log("Played all");
                }else {
                    console.log("Some parts were skipped");
                }
                }
        
    },
}
</script>

<style  >    

:root {
  --star-size: 40px;
  --star-color: #fff;
  --star-background: #fc0;
}

.Stars {
  --percent: calc(var(--rating) / 5 * 100%);
  display: inline-block;
  font-size: var(--star-size);
  font-family: Times;
  line-height: 1;
}
.Stars::before {
  content: "★★★★★";
  letter-spacing: 3px;
  background: linear-gradient(90deg, var(--star-background) var(--percent), var(--star-color) var(--percent));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#body {
  background: rgb(145, 19, 19);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

* {
  position: relative;
  box-sizing: border-box;
}

</style>
