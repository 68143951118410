<template>
<!-- <div class="flex lg:hidden left-0 absolute top-19 z-30">
    <button @click="toggleClassMenu" class="bg-black opacity-50 rounded-r-md text-white border border-white" aria-label="Open Menu" style="z-index: 289;">
        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" class="w-8 h-8 text-white">
            <path d="M4 6h16M4 12h16M4 18h16"></path>
        </svg>
    </button>
</div> -->
<div class="layout min-h-screen bg-red-50 z-40" id="clickfun">
    <!-- 2nd nav -->
    <div class="leftmenu absolute z-50 left-0 h-full flex flex-col lg:hidden justify-start items-start w-full font-medium text-gray-100 bg-white">
        <div class="w-44 md:w-52 h-full flex flex-col gap-2 md:gap-3" style="background-color:#222d32">
            <div class='cursor-pointer'>
                <div class="w-44 md:w-52 text-left hover:bg-teal-700 text-sm py-2 border-gray-400 flex gap-1 px-4" :class="{'border-teal-400 bg-gray-900 border-l-4' : actived=='Dashboard'}">
                    <img src="../assets/dashboard.svg" class="w-5 h-5">
                    <h1>Dashboard</h1>
                </div>
            </div>
            <div class='cursor-pointer'>
                <div class="w-44 md:w-52 text-left hover:bg-teal-700 text-sm py-2 border-gray-400 flex gap-1 px-4" :class="{'border-teal-400 bg-gray-900 border-l-4' : actived=='studentlist'}">
                    <img src="../assets/studentslist.svg" class="w-5 h-5">
                    <h1>Courses</h1>
                </div>
            </div>

            <div class='cursor-pointer '>
                <div class="w-44 md:w-52 text-left hover:bg-teal-700 text-sm py-2 border-gray-400 flex gap-1 px-4" :class="{'border-teal-400 bg-gray-900 border-l-4' : actived=='messages'}">
                    <img src="../assets/message.svg" class="w-5 h-5">
                    <h1>Messages</h1>
                </div>
            </div>

            <div class='cursor-pointer '>
                <div class="w-44 md:w-52 text-left hover:bg-teal-700 text-sm py-2 border-gray-400 flex gap-1 px-4" :class="{'border-teal-400 bg-gray-900 border-l-4' : actived=='assignments'}">
                    <img src="../assets/assignment.svg" class="w-5 h-5" alt="">
                    <h1>Accounts</h1>
                </div>
            </div>

            <!-- <div :to='{name :"InstructorStudents"}' :class="{'bg-sky-100' : actived=='Students'}">
        <div class="w-44 md:w-52 text-left hover:bg-teal-700 text-sm py-2 border-gray-400 flex gap-1 px-4">
          <h1>Documents</h1>
        </div>
      </div> -->
            <div>
                <div class="w-44 md:w-52 text-left hover:bg-teal-700 text-sm py-2 border-gray-400 flex gap-1 px-4" :class="{'border-teal-400 bg-gray-900 border-l-4' : actived=='events'}">
                    <img src="../assets/events.svg" class="w-5 h-5" alt="">
                    <h1>Events</h1>
                </div>
            </div>
            <div>
                <div class="w-44 md:w-52 text-left hover:bg-teal-700 text-sm py-2 border-gray-400 flex gap-1 px-4" :class="{'border-teal-400 bg-gray-900 border-l-4' : actived=='forums'}">
                    <img src="../assets/forums.svg" class="w-5 h-5" alt="">
                    <h1>Forums</h1>
                </div>
            </div>
        </div>
        <img @click="lefnev=false" src="../assets/closebutton.svg" class="menu-icon absolute w-8 h-8 right-2">
    </div>

    <!-- left nav for lg screen -->
    <div class="largerLayout w-48 2xl:w-56 hidden h-full lg:flex flex-col justify-start items-start text-gray-100 font-medium relative" style="background-color:#222d32">
        <!-- <img src="../../assets/leftArrow.svg" class="w-8 p-1 LeftArrow absolute -right-3.5 top-3.5 z-50 bg-teal-500 hover:bg-teal-600 rounded-lg" @click="slideFn" /> -->
        <!-- <div class="collapseDiv w-48 2xl:w-56 flex bottom-0 cursor-pointer px-4 py-1 gap-2 border-b border-gray-500" @click="slideFn">
            <img src="../assets/leftArrow.svg" class="w-7 p-1 LeftArrow " />
            <p class="collapseText">Collapse sidebar</p>
        </div> -->
        <div class='cursor-pointer'>
            <div class="contentval w-48 2xl:w-56 text-left py-4 hover:bg-teal-700 border-gray-400 flex gap-2 px-4" :class="{'border-teal-400 bg-gray-900 border-l-4' : actived=='Dashboard'}">
                <img src="../assets/dashboard.svg" class="w-6 h-6">
                <h1>Dashboard</h1>
            </div>
        </div>
        <div class='cursor-pointer'>
            <div @click="collapse('Course')" class="Course contentval w-48 2xl:w-56 text-left py-4 hover:bg-teal-700 border-gray-400 flex gap-2 px-4 items-center justify-between" :class="{'border-teal-400 bg-gray-900 border-l-4' : actived=='studentlist'}">
                <div class=" w-full h-full flex gap-2">
                    <img src="../assets/studentslist.svg" class="w-6 h-6">
                    <h1>Courses</h1>
                </div>
                <div class="float-right flex">
                    <font-awesome-icon class="plus text-sm text-white self-center" :icon="['fas','plus']" />
                    <font-awesome-icon class="minus text-sm text-white self-center hidden" :icon="['fas','minus']" />
                </div>
            </div>
            <div class="CourseCollapse w-48 2xl:w-56 text-left flex flex-col gap-2 px-4 hidden">
                <h1 class="hover:bg-teal-700 border-gray-400 px-4">My Course</h1>
                <h1 class="hover:bg-teal-700 border-gray-400 px-4">Course Forums</h1>
                <h1 class="hover:bg-teal-700 border-gray-400 px-4">Take Quiz</h1>
                <h1 class="hover:bg-teal-700 border-gray-400 px-4">Assignments</h1>
                <h1 class="hover:bg-teal-700 border-gray-400 px-4">Events</h1>
            </div>
        </div>

        <div class='cursor-pointer'>
            <div class="contentval w-48 2xl:w-56 text-left py-4 hover:bg-teal-700 border-gray-400 flex gap-2 px-4" :class="{'border-teal-400 bg-gray-900 border-l-4' : actived=='messages'}">
                <img src="../assets/message.svg" class="w-6 h-6">
                <h1>Messages</h1>
            </div>
        </div>

        <div class='cursor-pointer'>
            <div class="contentval w-48 2xl:w-56 text-left py-4 hover:bg-teal-700 border-gray-400 flex gap-2 px-4" :class="{'border-teal-400 bg-gray-900 border-l-4' : actived=='Accounts'}">
                <img src="../assets/assignment.svg" class="w-6 h-6" alt="">
                <h1>Accounts</h1>
            </div>

        </div>
        <!-- <div :to='{name :"InstructorStudents"}' :class="{'border-teal-400 bg-gray-900 border-l-4' : actived=='documents'}">
      <div class="contentval w-48 2xl:w-56 text-left py-4 hover:bg-teal-700 border-gray-400 flex gap-2 px-4">
        <h1>Documents</h1>
      </div>
      </div> -->
        <div>
            <div class="contentval w-48 2xl:w-56 text-left py-4 hover:bg-teal-700 border-gray-400 flex gap-2 px-4" :class="{'border-teal-400 bg-gray-900 border-l-4' : actived=='events'}">
                <img src="../assets/events.svg" class="w-6 h-6" alt="">
                <h1>Events</h1>
            </div>
        </div>
        <div>
            <div class="contentval w-48 2xl:w-56 text-left py-4 hover:bg-teal-700 border-gray-400 flex gap-2 px-4" :class="{'border-teal-400 bg-gray-900 border-l-4' : actived=='forums'}">
                <img src="../assets/forums.svg" class="w-6 h-6" alt="">
                <h1>Forums</h1>
            </div>
        </div>

    </div>
</div>
</template>

<script>
import {
    mapGetters
} from 'vuex'
export default {
    data() {
        return {

        }
    },
    computed: {
        ...mapGetters(['userInfo'])
    },

    mounted() {
        var oppMenu = document.querySelector(".menu-icon");
        oppMenu.addEventListener("click", this.toggleClassMenu, false);
    },
    methods: {
        collapse(collapseName) {
            var collapse = document.querySelector(`.` + collapseName + `Collapse`);
            collapse.classList.toggle("hidden");

            console.log(document.querySelector(`.` + collapseName).childNodes[1].childNodes.length)
            for (let i = 0; i < document.querySelector(`.` + collapseName).childNodes[1].childNodes.length; i++) {

                document.querySelector(`.` + collapseName).childNodes[1].childNodes[i].classList.toggle("hidden");
            }

        },

        slideFn() {
            console.log("Slide")
            document.querySelector(".LeftArrow").classList.toggle("rotateimg");
            document.querySelector(".collapseText").classList.toggle("hidden");
            document.querySelector(".collapseDiv").classList.toggle("border-none");

            var largelayout = document.querySelector(".largerLayout")
            if (!largelayout.classList.contains("app-menu-close")) {
                largelayout.classList.add("app-menu-close");
            } else {
                largelayout.classList.remove("app-menu-close");
            }
        },

        toggleClassMenu() {
            console.log("toggle");
            var layout = document.querySelector(".layout");
            if (!layout.classList.contains("app-menu-open")) {
                layout.classList.add("app-menu-open");
            } else {
                layout.classList.remove("app-menu-open");
            }
        }
    }
}
</script>

<style scoped>
.largerLayout {
    transition: width 1.5s, ;
}

.rotateimg {
    transform: rotate(180deg) translate(-5px);
}

.contentval {
    transition: width 1.5s, ;
}

.leftmenu {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    transition: transform 150ms linear;
}

.app-menu-open .leftmenu {
    -webkit-transform: none;
    transform: none;
    transition: transform 150ms linear;
}

.app-menu-close {
    width: 60px;
    transition: width 1.5s, ;
    margin: auto;
}

.app-menu-close .contentval {
    width: 60px;
    padding-left: 0px;
    padding-right: 0px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.app-menu-close .contentval h1 {
    display: none;
}
</style>
