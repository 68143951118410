<template>
<div class="fixed top-0 left-0 z-50 bg-black bg-opacity-60 h-screen w-full flex items-center">
    <div class="relative w-auto mx-auto rounded-lg bg-white p-12 text-gray-700 text-center shadow-xl">
        <div @click="$emit('cancel')" class="absolute right-4 top-0 text-6xl text-right font-light primary-text-color cursor-lg cursor-pointer">&times;</div>
        <h1 class="font-bold text-2xl p-2">You are attempting to log out of  {{this.title}} </h1>
        <h2 class="p-4 text-lg font-semibold">Are You sure?</h2>
        <PrimaryButton @click="$emit('logOut')" btnText="Log Out"/>
    </div>
</div>
</template>

<script>
import PrimaryButton from "@/components/buttons/PrimaryButton.vue";
export default {
    components: { PrimaryButton },
    data() {
        return {

            title: ""
        }
    },
    created() {
        this.title = process.env.VUE_APP_Title
    },


}
</script>

<style scoped lang="scss">
</style>