<template>
<Navigation active="rust" />
asdfasdf
</template>

<script>
import Navigation from "@/components/Navigation.vue";
export default {
    components: {
        Navigation
    }
}
</script>

<style>

</style>
