<template>
<div class="">
  <div class="bg-white">
    <router-view />
  </div>
</div>
</template>

<script>


export default {
  
}

</script>

<style>
* {
  box-sizing: border-box;
}
</style>