<template>
    <Navigation active="signup" />
<div class="px-5 mx-auto flex flex-col justify-center items-center min-h-screen">
    <div class="lg:w-2/5 md:w-1/2 md:pr-16 lg:pr-0 pr-0 hidden">
        <div class="lg:max-w-lg p-6   w-full  mb-12 lg:mt-0">
            <img class="object-cover object-center" src="../assets/coding.svg" alt="" />
        </div>
        <h1 class="font-poppin font-medium text-3xl text-gray-900">The great aim of education is not knowledge but action</h1>
        <p class="leading-relaxed mt-4">Learning anything requires commitment and the ability to push through the uncomfortable feeling of not being very good until reaching competency.</p>
    </div>

    <div class="lg:w-5/12 md:w-1/2 bg-gray-100 rounded-lg p-8 flex flex-col mx-auto">
        <h2 class="text-gray-900 text-lg font-medium title-font mb-5">Sign Up</h2>
        <!-- <div class="relative mb-4">
            <label for="full-name" class="leading-7 text-sm text-gray-600">Full Name</label>
            <input type="text" id="full-name" name="full-name" placeholder="Enter your full name" class="w-full bg-white rounded border border-gray-300 focus:border-yellow-500 focus:ring-2 focus:ring-yellow-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
        </div>
        <div class="relative mb-4">
            <label for="email" class="leading-7 text-sm text-gray-600">Email</label>
            <input type="email" id="email" name="email" placeholder="Email address" class="w-full  bg-white rounded border border-gray-300 focus:border-yellow-500 focus:ring-2 focus:ring-yellow-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
        </div>

        <div class="relative mb-4">
            <label for="password" class="leading-7 text-sm text-gray-600">Password</label>
            <input type="password" id="password" name="password" placeholder="Create password" class="w-full bg-white rounded border border-gray-300 focus:border-yellow-500 focus:ring-2 focus:ring-yellow-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
        </div>
         -->
        <form action="" @submit.prevent="SignUp" class="">
            <div class=" rounded-md shadow-sm -space-y-px mb-4">
                <div class="flex flex-col lg:flex-row gap-1 justify-between">

                    <div class="w-full">
                        <label for="first-name" class="py-2 text-xs md:text-base not-sr-only">First Name *</label>
                        <input ref="input" id="first_name" name="first_name" type="text" required class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="First Name">
                    </div>

                    <div class="w-full">
                        <label for="last-name" class=" py-2 text-xs md:text-base not-sr-only">Last Name * </label>
                        <input id="last-name" name="last_name" type="text" required class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Last Name">
                    </div>
                </div>
                <br>

                <div class="flex flex-col  items-center">
                    <div class="w-full">
                        <label for="Affiliation" class="py-2 text-xs md:text-base not-sr-only">Affiliation</label>
                        <input id="Affiliation" name="Affiliation" type="text" class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Affiliation">
                    </div>
                </div>
                <br>

                <div>
                    <label for="email-address" class=" py-2 text-xs md:text-base not-sr-only">Email address *</label>
                    <input id="email-address" name="email" type="email" autocomplete="email" required class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Email address">
                </div>
                <br>
                <div>
                    <div class="flex flex-col md:flex-row gap-1 justify-between relative">
                        <div class='w-full'>
                            <label for="password" class="py-2 text-xs md:text-base not-sr-only">Password *</label>
                            <div class='flex'>
                                <input v-model="password_watch" id="password" name="password" @keyup="colorCode('coloralerts','password')" type="password" autocomplete="current-password" required pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" class="appearance-none pr-8 relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Password">
                                <img v-if="passwordPrimary=='password'" src="../assets/eyeslash.svg" @click="hideShow('password')" alt="" class=" cursor-pointer inline-block z-50" style="margin-left: -30px; ">
                                <img v-else src="../assets/eye.svg" @click="hideShow('password')" alt="" class=" cursor-pointer inline-block z-50" style="margin-left: -30px; ">
                            </div>
                        </div>
                        <div class="w-full">
                            <label for="retype-password" class=" py-2 text-xs md:text-base not-sr-only">Retype Password *</label>
                            <div class="flex relative">
                                <input v-model="retype_password_watch" id="retype_password" name="retype_password" :class="[password_watch == retype_password_watch ? 'bg-gray-100' : 'bg-red-100']" type="password" autocomplete="current-password" required class="appearance-none relative pr-8 block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Retype Password">
                                <img v-if="passwordConfirm=='password'" src="../assets/eyeslash.svg" @click="hideShow('retype_password')" alt="" class=" cursor-pointer inline-block z-50" style="margin-left: -30px; ">
                                <img v-else src="../assets/eye.svg" @click="hideShow('retype_password')" alt="" class=" cursor-pointer inline-block z-50" style="margin-left: -30px; ">
                                <img id="exclamation" src="../assets/exclamationMark.svg" @click="clickss=!clickss" alt="" class="absolute -right-5 bottom-2 cursor-pointer inline-block z-30 " style="margin-right: 1px; ">
                            </div>
                        </div>
                        <div id='keysforpassword' class='absolute hidden w-full bg-white z-50 border rounded-lg px-4 py-4 text-sm lg:text-base text-gray-600'>
                            <p> Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters Your password can’t be too similar to your other personal information.</p>
                        </div>
                    </div>
                    <ul id='coloralerts' style="display:none;" class='mt-1'>
                        <div class=' flex gap-1 '>
                            <li class='w-3/12 cursor-pointer  border-b-4 inline-block border-red-600'></li>
                            <li class='w-3/12 cursor-pointer  border-b-4 border-red-600'></li>
                            <li class='w-3/12 cursor-pointer  border-b-4 border-red-600'></li>
                            <li class='w-3/12 cursor-pointer  border-b-4 border-red-600'></li>
                        </div>
                    </ul>
                </div>
            </div>
            <button v-if="!loading" type="submit" class="w-full text-white rounded py-3 px-4 bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-r hover:from-blue-500 hover:to-cyan-500 ">signup</button>
            <button v-else type="submit" class=" mt-2  w-full text-white  bg-gradient-to-r from-cyan-500 to-blue-500  overflow-hidden border-0  focus:outline-none rounded text-md">
                <img class="mx-auto  h-10" src="../assets/Pulse.gif" alt="">
            </button>
            <p v-if="msg" class="font-bold text-red-600  py-3">{{msg}}</p>
        </form>
        <!-- <span class=" flex items-center justify-center space-x-2">
          <span class="h-px bg-gray-400 w-14"></span>
          <span class="font-normal ">or</span>
          <span class="h-px bg-gray-400 w-14"></span>
        </span> -->
        <!-- <div class="flex flex-row justify-center bg-gray-100" >
          <button type="button" class="group relative font-bold w-4/6 flex justify-center gap-3 items-center py-2 px-4 border border-transparent text-sm rounded-md text-gray-500 border-gray-400 focus:outline-none focus:ring-2 bg-gray-200 hover:bg-gray-100 ">
              <img class="google-icon" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"/> Continue With Google
          </button>
        </div> -->

        <p class="text-sm text-gray-700 mt-3 items-center text-center">already have an account? <a href="/login" class="px-1 text-blue-600">Login</a> </p>
        <!-- <router-link to="/login">
        <button class="mt-12 w-full text-white border-0 bg-gradient-to-r from-red-500 via-yellow-500 to-yellow-500
                    hover:from-pink-600 hover:via-yellow-500 hover:to-yellow-500
                    py-2 px-8 focus:outline-none hover:bg-yellow-600 rounded text-lg">
            Login
        </button>
        </router-link> -->
    </div>
</div>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import axios from "axios";
import {
    mapActions
} from "vuex";
export default {
    components: {
        Navigation
    },
    data: () => ({
        email: "",
        password: "",
        info: "",
        msg: "",
        retype_password_watch: "",
        password_watch: "",
        passwordConfirm: "password",
        passwordPrimary: "password",
        loading : false

    }),
    props: ["orgtoken", "courseid"],
    mounted() {
        this.$refs.input.focus()
        var PWD = document.getElementById("password");

        PWD.onfocus = function () {
            document.getElementById("coloralerts").style.display = "block";
        }

        PWD.onblur = function () {
            document.getElementById("coloralerts").style.display = "none";
        }
        document.getElementById("exclamation").addEventListener("mouseover", function () {
            document.getElementById("keysforpassword").style.display = "block";
        })
        document.getElementById("exclamation").addEventListener("mouseout", function () {
            document.getElementById("keysforpassword").style.display = "none";
        })

    },
    methods: {
        ...mapActions(["loginUser"]),
        msg_change() {
            this.msg = ""
            this.loading = false
        },

        colorCode(data, inputclass) {
            document.getElementById(`${inputclass}`).value

            var PWD = document.getElementById(`${inputclass}`);
            var lowerCaseLetters = /[a-z]/g;
            var upperCaseLetters = /[A-Z]/g;
            var numbers = /[0-9]/g;
            console.log(document.getElementById(`${data}`).lastChild.childNodes.forEach(el => {
                console.log(el.style["border-color"] = 'red')
            }))

            if ((PWD.value.match(lowerCaseLetters) || PWD.value.match(upperCaseLetters) || PWD.value.match(numbers) || PWD.value.length >= 8) && PWD.value.length != 0) {
                document.getElementById(`${data}`).lastChild.childNodes[0].style["border-color"] = 'green'

                if ((PWD.value.match(lowerCaseLetters) && PWD.value.match(upperCaseLetters)) ||
                    (PWD.value.match(lowerCaseLetters) && PWD.value.match(numbers)) ||
                    (PWD.value.match(lowerCaseLetters) && PWD.value.length >= 8) ||
                    (PWD.value.match(upperCaseLetters) && PWD.value.match(numbers)) ||
                    (PWD.value.match(upperCaseLetters) && PWD.value.length >= 8) ||
                    (PWD.value.match(numbers) && PWD.value.length >= 8)) {

                    document.getElementById(`${data}`).lastChild.childNodes[1].style["border-color"] = 'green'

                    if ((PWD.value.match(lowerCaseLetters) && PWD.value.match(upperCaseLetters) && PWD.value.match(numbers)) ||
                        (PWD.value.match(lowerCaseLetters) && PWD.value.match(numbers) && PWD.value.length >= 8) ||
                        (PWD.value.match(lowerCaseLetters) && PWD.value.length >= 8 && PWD.value.match(upperCaseLetters)) ||
                        (PWD.value.match(upperCaseLetters) && PWD.value.match(numbers) && PWD.value.length >= 8)

                    ) {
                        document.getElementById(`${data}`).lastChild.childNodes[2].style["border-color"] = 'green'
                        if (PWD.value.match(lowerCaseLetters) && PWD.value.match(upperCaseLetters) && PWD.value.match(numbers) && PWD.value.length >= 8) {
                            document.getElementById(`${data}`).lastChild.childNodes[3].style["border-color"] = 'green'
                        } else {
                            document.getElementById(`${data}`).lastChild.childNodes[3].style["border-color"] = 'red'
                        }
                    } else {
                        document.getElementById(`${data}`).lastChild.childNodes[2].style["border-color"] = 'red'

                    }
                } else {
                    document.getElementById(`${data}`).lastChild.childNodes[1].style["border-color"] = 'red'
                }
            } else {

                document.getElementById(`${data}`).lastChild.childNodes[0].style["border-color"] = 'red'
            }

        },

        hideShow(id) {
            const passwordField = document.querySelector(`#${id}`)
            passwordField.getAttribute('type') === 'password' ? passwordField.setAttribute('type', 'text') : passwordField.setAttribute('type', 'password')
            switch (id) {
                case "password":
                    this.passwordPrimary = passwordField.getAttribute('type')
                    break;
                case "retype_password":
                    this.passwordConfirm = passwordField.getAttribute('type')
                    break;
            }
        },

        SignUp: function (submitEvent) {
            this.loading = true
            var emailID = submitEvent.target.elements.email.value;
            // console.log(emailID)
            var passWord = submitEvent.target.elements.password.value;
            var passWordRetype = submitEvent.target.elements.retype_password.value;
            var first_name = submitEvent.target.elements.first_name.value;
            var last_name = submitEvent.target.elements.last_name.value;

            var dataSignUp = {}
            dataSignUp['email'] = emailID.toLowerCase(),
                dataSignUp['password'] = passWord,
                dataSignUp['first_name'] = first_name,
                dataSignUp['is_active'] = false,
                dataSignUp['last_name'] = last_name

            if (passWord == passWordRetype) {
                if (this.courseid != 'Admin') {

                    // console.log("dataSignUp", dataSignUp)
                    // console.log(this.orgtoken, this.courseid)
                    axios.post(`${process.env.VUE_APP_API}/student_registeration/${this.orgtoken}/${this.courseid}/`, dataSignUp)
                        .then(async response => {
                            let data = {
                                username: emailID.toLowerCase(),
                                password: passWord,
                            }
                            await this.loginUser(data);
                            setTimeout(() => {
                                this.$router.push({
                                    name: 'Thanks'
                                });
                            }, 1000)
                            // console.log(response)
                        })
                } else {
                    axios.post(`${process.env.VUE_APP_API}/instructor_registeration/${this.orgtoken}/`, dataSignUp)
                        .then(async response => {
                            let data = {
                                username: emailID.toLowerCase(),
                                password: passWord,
                            }
                            await this.loginUser(data);
                            setTimeout(() => {
                                this.$router.push({
                                    name: 'Thanks'
                                });
                            }, 1000)
                            // console.log(response)
                        })
                }
            } else {
                this.msg = "Password mismatched!!!"
                setTimeout(this.msg_change , 3000)

            }
        }

    }
}
</script>

<style>

</style>
