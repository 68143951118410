<template lang="">
<Navigation />
<FlashMessage v-if="this.messages.text != ''" :message="this.messages" />
<div class="max-w-4xl mx-auto">
    <div v-if="blog_details != ''" class="w-full min-h-screen bg-opacity-50 py-4">
        <div class="max-w-4xl mx-auto py-4 bg-white ">
            <v-md-preview :text="blog_details[0].content"></v-md-preview>
        </div>
        <div class="flex gap-8 bg-white justify-center py-2 sticky bottom-2 mx-auto w-64 rounded-full" style="z-index:5000;box-shadow: 0px 2px 5px 0px lightblue;border: 1px solid lightblue;">
            <div class="flex gap-2 items-center">
                <span class="text-lg">{{blog_details[0].likes.length}}</span>
                <font-awesome-icon v-if="blog_details[0].likes.includes(this.userInfo.user.id.toString())" class="text-2xl text-blue-700 self-center h-5 w-5" :icon="['fas','thumbs-up']" />
                <font-awesome-icon v-else @click="liketheblog" class="text-2xl text-gray-400 hover:text-blue-700 self-center h-5 w-5" :icon="['fas','thumbs-up']" />
            </div>
            <div @click="is_commentspopup= !is_commentspopup" class="flex gap-2 items-center">
                <!-- <font-awesome-icon class="text-2xl text-gray-400 hover:text-gray-600 self-center h-6 w-6" :icon="['fas','comment-dots']" /> -->
                <span class="text-lg">{{blog_details[0].comment_set}}</span>
                <div class="hover:text-blue-700 cursor-pointer">Comments</div>
            </div>
        </div>
    </div>

    <!-- comments -->
    <div v-if="is_commentspopup" class="w-full md:w-3/12 2xl:w-2/12 min-w-fit border h-screen fixed right-0 top-0 bg-white overflow-y-auto" style="z-index:5001;min-width: 360px;scrollbar-color:gray white">
        <div>
            <div @click="this.is_commentspopup=false" class="absolute right-4 top-2 cursor-pointer" style="z-index:5002">
                <img class='h-5 w-5 md:h-8 md:w-8' src="https://img.icons8.com/cotton/46/undefined/delete-sign--v2.png" />
            </div>
            <div>
                <form ref="referencecomment" @submit.prevent="commentPost" class="flex flex-col p-4">
                    <h5 class="text-2xl text-gray-600 font-semibold">Comments</h5>
                    <textarea name="blogcomments" id="blogComments" cols="60" rows="3" class="w-full rounded text-sm"></textarea>
                    <button type="submit" class="gradient-btn mt-3 w-32">Submit</button>
                </form>

                <div class="py-4 px-2">
                    <div v-for="items in this.blog_comment" :key="items" class="comments px-2 flex flex-col gap-4 my-2">
                        <div class="flex gap-1 items-center">
                            <img src="https://assets.website-files.com/5f52d24bbe17c5455b1c6b7f/5f52d24bbe17c5a98f1c6c3f_norma-hicks-academy.jpg" alt="" class="card-post-author-picture">

                            <div class="mb-0 pb-0 capitalize flex flex-col leading-tight text-sm"> {{items.user_id.display_name}}
                                <small class="text-sm"> {{new Date(items.create_date).toLocaleDateString('en-IN', {day: 'numeric',month: 'long',year: 'numeric'})}} </small>
                            </div>
                        </div>
                        <p class="px-2 text-sm">{{items.comment}}</p>

                        <div class="flex gap-1 float-right justify-between">
                            <div v-if="parseInt(items.reply_comment)>0" @click="replyApiCallbacklist(items.id)" class="flex items-center gap-2 cursor-pointer">
                                <font-awesome-icon class="text-lg text-gray-400 hover:text-blue-700" :icon="['fas', 'comment-dots']" />
                                <small class="text-gray-400 hover:text-blue-700">{{items.reply_comment}}</small>
                            </div>
                            <div v-else class="flex items-center gap-2">
                                <font-awesome-icon class="text-lg text-gray-400 " :icon="['fas', 'comment-dots']" />
                                <small class="text-gray-400">{{items.reply_comment}}</small>
                            </div>
                            <div class="flex items-center gap-2 cursor-pointer">
                                <font-awesome-icon class="text-lg text-gray-400 hover:text-blue-700" :icon="['fas', 'reply']" />
                                <button @click="replyCommentPopup(items.id)" class="text-sm hover:text-blue-700">Reply </button>
                            </div>
                        </div>
                        <div :id="items.id+'reply'" class="hidden">
                            <form ref="referencereplys" @submit.prevent="commentreplyPost($event,items.id)" class="flex flex-col p-4">
                                <textarea name="commentsreply" id="blogComments" cols="60" rows="3" class="w-full rounded text-sm"></textarea>
                                <button type="submit" class="gradient-btn mt-3 w-32 float-right">Reply</button>
                            </form>
                        </div>
                        <!-- comment reply -->
                        <div :id="items.id+'replylist'" class="commentreplys hidden border-l-2 border-green-600 px-2">
                            <div v-for="reply in this.comment_reply" :key="reply" class="flex flex-col gap-4 my-4 border rounded p-2">
                                <div class="flex gap-1 items-center">
                                    <img src="https://assets.website-files.com/5f52d24bbe17c5455b1c6b7f/5f52d24bbe17c5a98f1c6c3f_norma-hicks-academy.jpg" alt="" class="card-post-author-picture">

                                    <div class="mb-0 pb-0 capitalize flex flex-col leading-tight text-sm"> {{reply.user_id.display_name}}
                                        <small class="text-sm"> {{new Date(reply.create_date).toLocaleDateString('en-IN', {day: 'numeric',month: 'long',year: 'numeric'})}} </small>
                                    </div>
                                </div>
                                <p class="px-2 text-sm">{{reply.comment}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<Footer />
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";
import FlashMessage from "@/components/flashMessage.vue";
import {
    mapGetters
} from "vuex";
export default {
    data() {
        return {
            blog_details: "",
            blog_comment: "",
            comment_reply: "",
            is_commentspopup: "",
            messages: {
                text: "",
                type: 'null',
            },
        };
    },
    computed: mapGetters(["userInfo"]),
    props: ["blog_id"],
    components: {
        Navigation,
        Footer,
        FlashMessage
    },
    async created() {
        await this.getblogdetails();
    },

    methods: {
        async replyApiCallbacklist(el) {
            await axios
                .get(
                    `${process.env.VUE_APP_API}/blog-replay-comment/list/?comment_id=${el}`
                )
                .then((response) => {
                    this.comment_reply = response.data.data;
                });
            this.replyCommentPopupreply(el);
        },

        replyCommentPopupreply(el) {
            var id = el + "replylist";
            var commentreplys = document.querySelectorAll(".commentreplys");

            commentreplys.forEach(function (item) {
                if (item.id == id) {
                    if (item.classList.contains("hidden"))
                        item.classList.remove("hidden");
                    else item.classList.add("hidden");
                } else {
                    item.classList.add("hidden");
                }
            });
        },

        commentreplyPost(el, id) {
            console.log(el, id, "reply values");
            let bodyVal = {
                comment: el.target.elements.commentsreply.value,
                comment_id: parseInt(id),
                user_id: parseInt(this.userInfo.user.id),
            };

            axios
                .post(`${process.env.VUE_APP_API}/blog-replay-comment/post/`, bodyVal)
                .then((response) => {

                    if (response.data.message == "Replay Comments submitted successfully") {
                        this.messages = {
                            text: "Reply submitted successfully",
                            type: 'success',
                        }
                        setTimeout(() => {
                            this.messages = {
                                text: "",
                                type: 'null',
                            }
                            this.getblogdetails();
                        }, 1500);

                    } else {
                        this.messages = {
                            text: response.data.message,
                            type: 'error',
                        }
                        setTimeout(() => {
                            this.messages = {
                                text: "",
                                type: 'null',
                            }
                        }, 2000)
                    }

                });
        },
        replyCommentPopup(el) {
            document.getElementById(el + "reply").classList.toggle("hidden");
        },

        commentPost(el) {

            let bodyVal = {
                comment: el.target.elements.blogcomments.value,
                bolg_id: parseInt(this.blog_id),
                user_id: parseInt(this.userInfo.user.id),
            };
            axios
                .post(`${process.env.VUE_APP_API}/blog-comment/post/`, bodyVal)
                .then((response) => {

                    if (response.data.message == "Comments submitted successfully") {
                        this.messages = {
                            text: "Comments submitted successfully",
                            type: 'success',
                        }
                        setTimeout(() => {
                            this.messages = {
                                text: "",
                                type: 'null',
                            }
                            this.$refs.referencecomment.reset()
                            this.getblogdetails();
                        }, 1500);

                    } else {
                        this.messages = {
                            text: response.data.message,
                            type: 'error',
                        }
                        setTimeout(() => {
                            this.messages = {
                                text: "",
                                type: 'null',
                            }
                        }, 2000)
                    }
                });
        },
        liketheblog() {

            this.blog_details[0].likes.push(this.userInfo.user.id)
            let bodyVal = {
                'likes': this.blog_details[0].likes
            };
            axios
                .patch(`${process.env.VUE_APP_API}/blog-details/deleted/${this.blog_id}/`, bodyVal)
                .then((response) => {
                    console.log(response.data.data);
                    this.getblogdetails();
                });
        },
        async getblogdetails() {
            await axios
                .get(`${process.env.VUE_APP_API}/blog-details/list/?id=${this.blog_id}`)
                .then((response) => {
                    this.blog_details = response.data.data;
                });

            await axios
                .get(
                    `${process.env.VUE_APP_API}/blog-comments/list/?bolg_id=${this.blog_id}`
                )
                .then((response) => {
                    this.blog_comment = response.data.data;
                });
        },
    },
};
</script>

<style scoped>
p {
    margin: 0px;
}
.comments::after {
    content: "";
    border: 1px solid lightgray;
    margin: 4px 0px;
}
</style>
