<template>
<Navigation />
<div class="main-layout max-w-3xl  mx-auto flex items-center min-h-screen ">
    <div class="w-full  p-4 border card contact-us">
        <p class="text-2xl font-semibold text-blue-600">
            Order summary
        </p>
        <div class="grid grid-cols-2 mt-2 text-lg">
            <p class="pb-2 text-gray-700">
                Course name
            </p>
            <p class="text-left ml-4">
                {{title}}
            </p>
            <p class="pb-2 text-gray-700">
                Amount to be pay
            </p>
            <p class="text-left ml-4">
                {{this.price}}/lifetime
            </p>
        </div>
        <button v-if="orderData" class="gradient-btn float-right" @click="payload"> Proceed to Pay </button>
        <button v-if="!orderData" class="bg-gray-700 cursor-not-allowed text-white border-0 py-2 px-8 focus:outline-none rounded text-lg float-right" @click="payload"> Proceed to Pay </button>

    </div>
</div>
</template>

<script>
import {
    mapGetters
} from 'vuex';
import Navigation from "@/components/Navigation.vue";
import axios from "axios";
export default {
    props: ['amount', 'title', 'slug', 'courseId'],
    data() {
        return {
            result: null,
            orderData: null,
            script: `https://checkout.razorpay.com/v1/checkout.js`,

        }
    },
    components: {
        Navigation
    },
    computed: {
        ...mapGetters(["userInfo"])
    },
    methods: {
        redirect() {
            console.log("inside redirce", this)
            this.$router.push({
                name: "Dashboard"
            })
        },
        async payload() {
            console.log(this.result)
            const result = this.result
            if (!result) {
                alert('Failed to load razorpay script')
                return
            }
            var that = this
            const options = {
                key: process.env.VUE_APP_RAZORPAY_ID,
                amount: this.orderData.order_amount,
                currency: "USD",
                name: this.orderData.order_product,
                description: "Descrdiption of the payment",
                order_id: this.orderData.order_payment_id,
                image: "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
                handler: async function (response) {
                    // console.log("this:::",this)
                    console.log("razorpay_order_id:", response.razorpay_order_id)
                    // console.log("razorpay_payment_id:",response.razorpay_payment_id)
                    // console.log("checkout_logo",response.checkout_logo)
                    // console.log("razorpay_signature",response.razorpay_signature)
                    // console.log("name:",that.title)
                    // console.log("courseId:",that.courseId)
                    // console.log(response,'Razorpay response')
                    // window.location=`/success?id=${response.razorpay_order_id}&pay_id=${response.razorpay_payment_id}&signature=${response.razorpay_signature}&courses_name=${that.title}&amount=${that.amount}&courseId=${that.courseId}`

                    // It is function executed on success
                    console.log(that.title, "course Name")

                    // Check the Payment with rezorpay_order_id
                    var courseDetail
                    await axios.get(`${process.env.VUE_APP_API}/checout/${response.razorpay_payment_id}/?format=json`)
                        .then(res => {
                            courseDetail = res.data
                        })

                    let detail = {
                        pay_id: courseDetail['data']['id'],
                        payment_method: courseDetail['data']['method'],
                        amount: courseDetail['data']['amount']/100,
                        paid_email: courseDetail['data']['email'],
                        phone: courseDetail['data']['contact'],
                        country: "null",
                        invoice_id: `${courseDetail['data']['invoice_id']}`,
                        customer: "",
                        invoice_detail: "",
                        payment_detail: "",
                        price_detail: "",
                        product_detail: "",
                        subscription: `${that.title}`,
                        subscription_item: "",
                        user_id: that.userInfo.user.id
                    }

                    // UserPayment Details push into payment table
                    await axios.post(`${process.env.VUE_APP_API}/PaymentPage/?Key=${that.userInfo.token_key}`, detail)
                        .then(res => {
                            this.payment_Detail = res.data
                        })

                    const permissiondetails = {

                        user: that.userInfo.user.id,
                        token_id: that.userInfo.token_key,
                        permission_path: [`${that.title}`],
                        course: [parseInt(that.courseId)],
                    }

                    // User details push to UserPermision table
                    await axios.post(`${process.env.VUE_APP_API}/userpermission/?Key=${that.userInfo.token_key}`, permissiondetails)
                        .then(res => {
                            console.log(res.data)
                        })

                    window.location = `/success?id=${response.razorpay_order_id}&pay_id=${response.razorpay_payment_id}&signature=${response.razorpay_signature}&courses_name=${that.title}&amount=${that.amount}&courseId=${that.courseId}`

                },
                prefill: {
                    name: this.userInfo.user.display_name,
                    email: this.userInfo.user.email,
                    contact: ""
                }
            };
            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
        },
        async loadRazorPay() {

            return new Promise(resolve => {
                const script = document.createElement('script')
                script.src = this.script
                script.onload = () => {
                    resolve(true)
                }
                script.onerror = () => {
                    resolve(false)
                }
                document.body.appendChild(script)
            })
        },
        createPayment(amount, title) {
            let data = {
                amount: parseInt(amount),
                name: title
            }
            console.log(data,'price')
            axios.post(`${process.env.VUE_APP_API}/pay/`, data)
                .then(res => {
                    this.orderData = res.data.order
                })
        }

    },
    async created() {

        this.price = new Intl.NumberFormat('hi-IN', {
            style: 'currency',
            currency: 'INR',
            maximumFractionDigits: 0,
        }).format(this.amount);

        await this.createPayment(this.amount, this.title)
        this.result = await this.loadRazorPay()

    }
}
</script>

<style scoped>
.main-layout {
    height: 80vh;
}

.grid-cols-2 {
    grid-template-columns: 30% 70%;
    gap: 1rem;
}
</style>
