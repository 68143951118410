<template>
<Navigation />
<div class="flex flex-col h-screen justify-center items-center gap-4">
    <h1 class="not-found text-8xl font-bold">
        Oops!
    </h1>
    <h1 class="uppercase font-bold text-3xl">
        404 - Page not found
    </h1>
    <p class="w-1/3 text-center">The page you are looking for might have been removed had its name changed or is temporarily unavaliable</p>
    <a href="/" class="gradient-btn">
        Go to homepage
    </a>
</div>
</template>

<script>
import Navigation from '@/components/Navigation.vue'
export default {
    components: {
        Navigation
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap');

h1.not-found {
    font-size: 200px;
    font-family: 'montserrat', sans-serif;
    color: white;
    background: url("https://images.unsplash.com/photo-1496715976403-7e36dc43f17b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1470&q=80");
    background-size: cover;
    background-position: left;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
</style>
