// Usally can use for next/submit.1
<template>
<button class="gradient-btn font-bold"> {{btnText}} </button>
</template>

<script>
export default {
    props: { 
        btnText: {
            type: String,
            default: "Submit"
        }
    }
}
</script>