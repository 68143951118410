<template lang="languageChoosed">
<Navigation active='codeeditor' />
<div class="h-screen bg-gray-200">

    <div class="flex flex-col md:flex-row h-4/6 gap-2 ">
        <div class="w-full md:w-1/2  m-2">
            <form action="" @submit.prevent="Listout" class="">
                <!-- <div class="w-full bg-gray-700 block">
                    <button v-if="!runing" type="submit" class="float-right block border border-gray-400 bg-gray-300 hover:bg-white hover:text-black px-6 py-0.5 rounded-sm font-bold">Run</button>
                    <button v-else-if="runing" type="submit" disabled class="float-right border border-gray-400 bg-gray-300 px-6 py-0.5 font-bold">Loading</button>

                </div>
                <div class="w-full clear-both">
                    <a @click="languageChoosed = 'python'" :class="[languageChoosed=='python' ? 'bg-white text-gray-700 pt-6 ': 'bg-gray-700 text-white ' ]" 
                    class="p-3  border border-black font-bold text-xl  cursor-pointer">
                        Python
                    </a>
                    <a @click="languageChoosed = 'rust'" :class="[languageChoosed=='rust' ? 'bg-white text-gray-700 pt-6': 'bg-gray-700 text-white ' ]" 
                    class="p-3  border border-black font-bold text-xl cursor-pointer ">
                        Rust
                    </a>

                </div> -->

                <div class="flex justify-between py-1">
                    <div class="flex clear-both gap-0.5">
                    <div class="flex   border text-center font-bold border-black text-base cursor-pointer items-center px-1" :class="[languageChoosed=='python' ? 'bg-green-800 text-white': 'bg-gray-300 text-black ' ]">
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Python-logo-notext.svg/1024px-Python-logo-notext.svg.png" class="w-5 h-5" >
                        <a @click="languageChoosed = 'python'"  
                    class="w-20">
                        Python
                    </a>
                    </div>
                    
                    <div class="flex  text-center border border-black font-bold text-base items-center cursor-pointer px-1" :class="[languageChoosed=='rust' ? 'bg-green-800 text-white ': 'bg-gray-300 text-black ' ]">    
                    <img src="https://www.nicepng.com/png/full/380-3805125_the-systems-programming-language-rust-has-reached-version.png" class="w-6 h-6">
                    <a @click="languageChoosed = 'rust'"  
                    class=" w-20  ">
                        Rust
                    </a>
                    </div>
                    </div>
                    <div class="flex items-center">
                        <button v-if="!runing" type="submit" class="float-right block border border-gray-400 bg-gray-300 hover:bg-white hover:text-black px-6 py-0.5 rounded-sm font-bold">Run</button>
                    <button v-else-if="runing" type="submit" disabled class="float-right border border-gray-400 bg-gray-300 px-6 py-0.5 font-bold">Loading</button>
    
                    </div>
                </div>


                <div class="h-4/6 border border-black" style="overflow:hidden;resize:none;">
                    <!-- <textarea v-model="textarea" class="w-full  border-4 border-black" name="codeblock" id="codeblock" cols="30" rows="25"></textarea> -->
                    <VAceEditor class="" v-model:value="content" :lang="languageChoosed" theme="chrome" style="height:50rem;font-size:1rem;scrollbar-color: gray white "> </VAceEditor>
                </div>
            </form>

        </div>

        <div class=" w-full md:w-1/2 m-2 mt-2.5 ">
            <h1 class="px-1 py-1 top-6 right-4 inline-block  rounded-sm font-bold"> Result</h1>
            <div class="border-b bg-white  border border-black  text-black m-1 overflow-auto " style="height:50rem;scrollbar-color: gray white">

                <pre class=""> {{message}} </pre>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import {
    VAceEditor
} from 'vue3-ace-editor';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/mode-rust';
import 'ace-builds/src-noconflict/theme-chrome';
import 'ace-builds/src-noconflict/theme-twilight';

import axios from "axios";

export default {
    data() {
        return {
            message: "",
            content: "",
            languageChoosed: "python",
            runing: false,
        }
    },
    components: {
        Navigation,
        VAceEditor
    },
    methods: {
        Listout(e) {
            let that = this;
            var FormData = require('form-data');
            var data = new FormData();
            data.append('code', this.content);
            data.append('language', this.languageChoosed);

            data.append('version', this.languageChoosed == 'python' ? '3' : '1');
            this.runing = true

            var config = {
                method: 'post',
                url: `${process.env.VUE_APP_API}/compile/code`,

                data: data
            };

            axios(config)
                .then(function (response) {
                    that.message = response.data;
                    that.runing = false
                    console.log(that.message)
                })
                .catch(function (error) {
                    console.log(error);
                    that.runing = false
                });

            console.log(e)
        },
    },

}
</script>

<style scoped>
#app {
    font-family: "San Francisco UI", "Helvetica Neue", Helvetica, Arial,
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #343434;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
a {
    transition: all 0.3s ease-out;
}
</style>
