<template>
<div class="absolute right-10 md:right-20 lg:right-36">
    <router-link :to="{name:'password',params:{keyword:'change', keyval:userInfo.slug_id}}">
        <button class=" border px-2 bg-teal-300 text-black rounded hover:bg-teal-700 hover:text-white">Change Password</button>
    </router-link>
</div>
<div class='overflow-y-scroll h-screen flex justify-center mt-6 md:mt-0' style="scrollbar-color:gray white">
    <div class="w-11/12 p-0 md:p-4 flex justify-center ">
        <div class="mt-4 inline-block shadow-xl rounded-md overflow-x-auto md:overflow-y-scroll h-5/6">
            <table class="leading-normal ">
                <thead>
                    <tr>
                        <th class="px-2 lg:px-5 py-2 lg:py-4 items-center border-b-2 border-gray-500 bg-gray-200 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                            Sl.no
                        </th>
                        <th class=" px-2 lg:px-5 py-2 lg:py-4 border-b-2 border-gray-500 bg-gray-200 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                            Date
                        </th>
                        <th class="px-2 lg:px-5 py-2 lg:py-4 border-b-2 border-gray-500 bg-gray-200 text-center text-xs font-semibold  text-gray-600 uppercase tracking-wider">
                            Course
                        </th>
                        <th class=" px-2 lg:px-5 py-2 lg:py-4 border-b-2 border-gray-500 bg-gray-200 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                            Payment Method
                        </th>
                        <th class=" px-2 lg:px-5 py-2 lg:py-4 border-b-2 border-gray-500 bg-gray-200 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                            Payment Id
                        </th>
                        <th class=" px-2 lg:px-5 py-2 lg:py-4 border-b-2 border-gray-500 bg-gray-200 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                            Payment
                        </th>
                    </tr>
                </thead>
                <tbody v-if="tnxDetails.data">
                    <tr v-for="el,index in tnxDetails.data" :key="el">
                        <td class="px-2 lg:px-5 py-4 lg:py-4 border-b border-gray-200 bg-white text-xs lg:text-sm">
                            <div class="flex items-center">
                                <div class="ml-3">
                                    <p class="text-gray-900 whitespace-no-wrap">
                                        {{ index + 1 }}
                                    </p>
                                </div>
                            </div>
                        </td>
                        <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm">
                            <p v-if="el.start_from" class="text-center text-gray-900 whitespace-no-wrap">
                                {{el.start_from}}
                            </p>
                            <p v-else class="text-center text-gray-900 whitespace-no-wrap">
                                empty
                            </p>
                        </td>
                        <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm text-center">
                            {{el.subscription}}
                        </td>
                        <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm">
                            <p class="text-center text-gray-900 whitespace-no-wrap">
                                {{el.payment_method}}
                            </p>
                        </td>
                        <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm">
                            <p class="text-center text-gray-900 whitespace-no-wrap">
                                {{el.pay_id}}
                            </p>
                        </td>
                        <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm">
                            <p class="text-center text-gray-900 whitespace-no-wrap">
                                {{el.amount}}
                            </p>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm">
                            empty
                        </td>
                        <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm">
                            empty
                        </td>
                        <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm text-center">
                            empty
                        </td>
                        <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm">
                            <p class="text-center text-gray-900 whitespace-no-wrap">
                                empty
                            </p>
                        </td>
                        <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm">
                            <p class="text-center text-gray-900 whitespace-no-wrap">
                                empty
                            </p>
                        </td>
                        <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm">
                            <p class="text-center text-gray-900 whitespace-no-wrap">
                                empty
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

</div>
</template>

<script>
import axios from "axios"
import {
    mapGetters
} from "vuex";
export default {

    data() {
        return {
            tnxDetails: "",
        }
    },

    computed: mapGetters(["userInfo"]),

    async created() {
        await axios.get(`${process.env.VUE_APP_API}/paymentlist/?Key=${this.userInfo.token_key}`)
            .then(res => {

                if (Object.keys(res.data) == 'error') {
                    console.log('error')
                } else {
                    this.tnxDetails = res.data
                    this.tnxDetails.data.forEach(el => {
                        el.start_from = new Date(el.start_from).toLocaleString('en-IN', {
                            timeZone: 'Asia/Kolkata',
                            day: 'numeric',
                            month: 'numeric',
                            year: 'numeric'
                        });
                    })
                }
            })
    },
}
</script>

<style scoped>
th,
tr,
td,
table {
    padding: 10px;
}
</style>
