<template>
  <div class="">
  <Navigation />
      <!-- <router-link :to="{name :'instructorCourse'}" class="flex items-center px-4 py-2">
            <img class="h-8 w-8" src="../../assets/collection.svg"/>
            <button class="ml-2 py-2 px-4 border border-teal-600 select-none text-teal-700 hover:text-white text-md font-semibold hover:bg-teal-600 rounded">
              Course list
            </button>
      </router-link>
      <router-link :to="{name : 'AddCourse'}" class="px-4 py-2 flex items-center">
        <img class="h-8 w-8" src="../../assets/addcollection.svg"/>
          <button class="ml-2 py-2 px-4 border border-teal-600 select-none text-teal-700 hover:text-white text-md font-semibold hover:bg-teal-600 rounded">
          Add new course
        </button>
      </router-link> -->
  </div>

  <router-view />

</template>

<script>
import Navigation from '../../components/Navigation.vue'
export default {
  components: { Navigation },

}
</script>

<style>

</style>
