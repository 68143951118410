<template>
<div>
    <h1>
        This is a Resources page
    </h1>
</div>
  
</template>

<script>
export default {

}
</script>

<style>

</style>
