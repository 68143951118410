<template lang="">
<!-- Blog List -->
<div class="min-h-screen w-full ">
    <div>
        <Navigation />
        <div data-w-id="0b097d78-b776-9e09-a4b6-8ab657637224" style="" class="section learning-center wf-section">
            <div data-w-id="0b097d78-b776-9e09-a4b6-8ab657637225" style="" class="container-small-462px text-center w-container">
                <h2>Blogs</h2>
                <p>Discover great content to grow your skills in Design, Development and Marketing.</p>
            </div>
            <div class="container-default-1209px w-container">
                <div class="w-dyn-list">
                    <div class="learning-center-section-grid w-dyn-items  ">
                        <div v-for="el in blog_list" :key="el" data-w-id="0b097d78-b776-9e09-a4b6-8ab657637241" style="height:528px" role="listitem" class="post-card w-dyn-item my-2 mx-auto card">
                            <router-link :to="{name:'BlogPreview', params:{blog_id:el.id}}" class="card w-80 md:w-96" style="height:528px">
                                <a href="#" class="card-image-link post w-inline-block">
                                    <img v-if="el.thumbnail != null" :src="el.thumbnail" alt="" style="overflow: hidden;width:396px;height:208px" class="image course w-80 md:w-96">
                                    <img v-else src="https://assets.website-files.com/5f52d24bbe17c5455b1c6b7f/5f52d24bbe17c5ea4e1c6cd8_8%20Great%20Design%20Trends%20That%20Are%20Coming%20Back-thubmnail-image-academy-template.jpg" alt="" style="overflow: hidden;width:396px;height:208px" class="image course w-80 md:w-96"></a>
                                <div class="card-content">
                                    <a href="#" class="card-title-link w-inline-block">
                                        <h3 class="text-xl post-card">{{el.title}}</h3>
                                    </a>
                                    <p> Created @ {{ new Date(el.create_date).toLocaleDateString('en-IN', {day: 'numeric',month: 'long',year: 'numeric'}) }}</p>
                                    <div class="divider card-post"></div>
                                    <div data-w-id="6288b6c0-964c-5adc-f34f-d4b8510c1e2f" href="#" class="card-post-author-wrapper w-inline-block flex justify-between">
                                        <div class="card-post-author-wrapper w-inline-block">
                                            <img src="https://assets.website-files.com/5f52d24bbe17c5455b1c6b7f/5f52d24bbe17c5a98f1c6c3f_norma-hicks-academy.jpg" alt="" class="card-post-author-picture">
                                            <div class="card-post-author-name capitalize">
                                                {{el.bolger_id.display_name}}
                                            </div>
                                        </div>
                                        <div>
                                            {{el.likes.length}} Likes
                                        </div>
                                    </div>

                                </div>
                            </router-link>
                                <a href="#" class="category-wrapper w-inline-block">
                                    <div class="category-text">{{el.category_id.name}}</div>
                                </a>
                        </div>

                    </div>
                </div>
                <div data-w-id="0b097d78-b776-9e09-a4b6-8ab657637245" style="" class="flex flex-col md:flex-row justify-center gap-4">
                </div>
            </div>
        </div>
        <Footer />
    </div>

</div>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";
export default {
    data() {
        return {
            blog_list: '',
        }
    },
    components: {
        Navigation,
        Footer
    },
    created() {
        axios.get(`${process.env.VUE_APP_API}/blog-details/list/`)
            .then(response => {
                console.log(response.data.data)
                this.blog_list = response.data.data
            })
    }
}
</script>

<style lang="">
    
</style>
