<template>
<div class="">
    <v-md-editor id="sample" v-model="markvalues" 
                left-toolbar="undo redo clear| tip todo-list | h bold italic strikethrough quote | ul ol table hr | link image code | save"
                :disabled-menus="[]" 
                @upload-image="handleUploadImage"
                :source="markvalues" 
                height="700px">
    </v-md-editor>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'

export default {
    props:{
        markvalues : {type: String,
        default: '# Your text here'}
        },

    data() {
        return {
            markdown: "# Your text here",
            mdimg_Url : '',
        }
    },
    computed: {
        ...mapGetters(["userInfo"]),
    },
    mounted() {
        this.$nextTick(() => { 
            // this.scrollBoth()
            
        })
    },
    methods: {
    async handleUploadImage(event, insertImage, files) {
      // Get the files and upload them to the file server, then insert the corresponding content into the editor
      console.log(files[0]);
      var urls = `${process.env.VUE_APP_API}/mdfile_image_upload/?Key=${this.userInfo.token_key}`;
      var formData = new FormData();
      formData.append('file_uploaded', files[0]);
      var requestOptions = {
                        method: 'POST',
                        body: formData,
                        redirect: 'follow'
                      };
      fetch(urls, requestOptions).then(response => response.json())
      .then(result =>{ 
                    console.log(result.file_url)
                   this.mdimg_Url = result.file_url
                   insertImage({
                              url: this.mdimg_Url,
                              desc: 'desc',
                              // width: 'auto',
                              // height: 'auto',
                            });
                    
                    })
      .catch(error => console.log('error', error));

      // Here is just an example
      
    },
  },
}
</script>

<style>

</style>
