<template>
<Navigation active="python" />
  <div class="flex flex-col">
        <div class="flex flex-row w-full">
            <div class="flex flex-col w-full md:w-2/12">
                <div class="mt-0">
                <div class="bg-gray-200 h-12">
                    <nav class="md:ml-auto flex flex-row font-bold text-gray-700 items-center text-base justify-center">
                        <div @click="switchTab(1)" class="w-48 mr-5 p-3 hidden md:inline-flex hover:bg-yellow-300">
                            <ClipboardListIcon class="h-5 w-5 text-red-500 mx-2"/>
                                Tasks
                        </div>

                        <div @click="switchTab(2)" class="w-48 mr-5 p-3 hidden md:inline-flex hover:bg-yellow-300">
                            <ViewListIcon class="h-5 w-5 text-red-500 mx-2"/>
                            Lessons
                        </div>

                        <div @click="switchTab(3)" class="w-48 mr-5 p-3 hidden md:inline-flex hover:bg-yellow-300">
                            <BookOpenIcon class="h-5 w-5 text-red-500 mx-2"/>
                            Reads
                        </div>
                    </nav>
                </div>
                    
                         <div v-if="tasks">
                            
                                <div v-if="tab === 1" class="bg-gray-800">
                                    
                                    <div v-for="task in tasks" :key="task" class="flex flex-row px-2 py-2 border-b-2 items-center  hover:bg-gray-600">
                                        <div>
                                            
                                                <CheckIcon v-if="task.status" class="h-5 w-5 text-yellow-200 mx-2"/>
                                            
                                                <ArrowCircleRightIcon v-else class="h-5 w-5 text-yellow-200 mx-2"/>
                                            

                                        </div>
                                        <p class="ml-1 font-medium text-gray-50 text-lg truncate">
                                            {{ task.title }}
                                        </p>
                                    </div>
                                    
                                </div>
                            
                                <div v-if="tab === 2" class="bg-gray-800">
                                
                                    <div v-for="les in lessons" :key="les">

                                    
                                        <div @click="openLesson(les)" class="flex flex-row px-2 py-2 border-b-2 items-center  hover:bg-gray-600">
                                            <div >
                                                <CheckIcon v-if="les.status" class="h-5 w-5 text-yellow-200 mx-2"/>
                                                    
                                                <PlayIcon v-else class="h-5 w-5 text-yellow-200 mx-2"/>
                                                    
                                            </div>
                                            <p class="ml-1 font-medium text-gray-50 text-lg truncate">
                                                {{ les.title }}
                                            </p>
                                        </div>
                                    </div>
                                 
                                 </div>
                            
                                <div class="bg-gray-800">
                                
                                <div v-for="read in reads" :key="read">

                                
                                    <div class="flex flex-row px-2 py-2 border-b-2 items-center  hover:bg-gray-600">
                                    <div>
                                        <PaperClipIcon class="h-5 w-5 text-yellow-200 mx-2"/>
                                    </div>
                                    <p class="ml-1 font-medium text-gray-50 text-lg truncate">
                                        {{ read.topic }}
                                    </p>
                                    </div>
                                    </div>
                                
                                </div>
                            
                         </div>

                    
                        <div>
                            Loading tasks
                        </div>
                    
                </div>
            </div>

            
                <div v-if="tab === 1" class="middle flex flex-col w-full h-screen">
                <div class="w-full lg:w-6/12 text-white">
                <div class="flex flex-row justify-between bg-gray-800 mr-4 pl-4 h-12">
                    <h4 class="mt-2 mb-1 font-medium"> Python </h4>
                    <div class="mt-2 mb-1">
                    <button class="bg-yellow-500 hover:bg-yellow-400 h-8 mr-4 px-4 rounded-sm"> Undo </button>
                    <button class="bg-indigo-500 hover:bg-indigo-400 h-8 mr-4 px-4 rounded-sm"> Redo </button>
                    <button class="bg-green-600 hover:bg-green-500 h-8 mr-4 px-5 rounded-sm"> Run </button>
                    </div>
                </div>
                    <!-- <AceEditor
                        width="700px"
                        placeholder="Your python script here"
                        mode="python"
                        theme="github"
                        onChange={this.onChange}
                        fontSize={14}
                        showPrintMargin={true}
                        showGutter={true}
                        maxLines={100}
                        minLines={60}
                        wrap={true}
                        highlightActiveLine={true}
                        name="Python"
                        editorProps={{ $blockScrolling: true }}
                        setOptions={{
                        enableBasicAutocompletion: true,
                        enableLiveAutocompletion: true,
                        autoScrollEditorIntoView: true,
                        enableSnippets: true,
                        showLineNumbers: true,
                        tabSize: 4,
                    }}
                    commands={[{
                        name: 'cut',
                        bindKey: {win: 'Ctrl-x', mac: 'Command-x'},
                        exec: () => { console.log('Cutting') }
                        },
                        {
                        name: 'copy',
                        bindKey: {win: 'Ctrl-c', mac: 'Command-c'},
                        exec: () => { console.log('Copied') }
                        },
                        {
                        name: 'paste',
                        bindKey: {win: 'Ctrl-v', mac: 'Command-v'},
                        exec: () => { console.log('Pasting') }
                        }]}
                    /> -->
                </div>
                </div>

            
                <div v-if="tab === 2" class="middle flex flex-col w-full h-screen">
                    <div class="m-12 w-full">
                        { currentLesson.title }
                        <ReactPlayer width="640px" height="360px" controls="true"	 pip="true" url={ currentLesson.video_url } />
                    </div>

                </div>
            
                <div  class="middle flex flex-col w-full h-screen bg-pink-200"></div>
            

        </div>
    </div>
</template>

<script>
import Navigation from "@/components/Navigation.vue"
import {
    ViewListIcon,
    BookOpenIcon,
    PaperClipIcon,
    PlayIcon,
    CheckIcon,
    ArrowCircleRightIcon,
    ClipboardListIcon } from '@heroicons/vue/outline';
export default {

    components: {
        Navigation,
        ViewListIcon,
        BookOpenIcon,
        PaperClipIcon,
        PlayIcon,
        CheckIcon,
        ArrowCircleRightIcon,
        ClipboardListIcon
    }

}
</script>

<style>

</style>