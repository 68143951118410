<template>
<Navigation active="pricing" />
<div class="w-full mx-auto">
    <div class="w-full banner ">
        <div class="w-full max-w-7xl mx-auto h-full">
            <div class="flex items-center justify-center h-full">
                <div>
                    <h2>We're building software <br>
                        to manage business</h2>
                    <p>Lasted hunted enough an up seeing in lively letter. Had judgment out opinions property the supplied. </p>
                    <button class="bg-white border rounded-3xl w-32 h-12 text-black text-sm font-normal">Get Start</button>
                </div>
                <div>
                    <img src="https://validthemes.live/themeforest/appku/assets/img/dashboard/18.png" alt="">
                </div>
            </div>
        </div>
    </div>
    <div class="max-w-7xl w-full mx-auto h-64 flex flex-col justify-center items-center">
        <div class="text-center h-auto">
            <h3 class="text-4xl font-light">
                Over <strong class="text-5xl text-sky-700">150,000+</strong> client all over the world
            </h3>
        </div>
        <div class="py-2">
            <ul class="flex gap-4">
                <li class="text-2xl h-16 w-60 flex items-center justify-center">
                    Simustech
                </li>
                <li class="text-2xl h-16 w-60 flex items-center justify-center">
                    VVl
                </li>
                <li class="text-2xl h-16 w-60 flex items-center justify-center">
                    Nordic
                </li>
                <li class="text-2xl h-16 w-60 flex items-center justify-center">
                    Jain College
                </li>
            </ul>
        </div>
    </div>
    <div class=" w-full mx-auto" style="background-color:#edf5ff;height:750px">
        <div class="max-w-7xl w-full flex md:flex-col lg:flex-row justify-center items-center mx-auto h-full">
            <div class="w-2/3 flex items-center" style="height:420px">
                <div class="relative h-full" style="width:600px">
                    <img src="https://validthemes.live/themeforest/appku/assets/img/dashboard/16.png" alt="" class="" style="height:420px">
                    <img src="https://validthemes.live/themeforest/appku/assets/img/dashboard/11.png" alt="" class="h-72 absolute -right-20 -bottom-14">
                </div>
            </div>
            <div class="w-1/3">
                <small>Story about us</small>
                <h3>
                    Perfect place to Design,<br> Development, Software.
                </h3>
                <ul style="list-style-type: circle;">
                    <li>
                        <h5>Free Download App</h5>
                        <small>
                            Fruit defer in party me built under first. Forbade him but savings sending ham general. So play do in near park that pain.
                        </small>
                    </li>
                    <li>
                        <h5>Trusted and Reliable</h5>
                        <small>
                            Fruit defer in party me built under first. Forbade him but savings sending ham general. So play do in near park that pain.
                        </small>
                    </li>
                </ul>
                <div class="flex gap-2 items-center justify-start py-2">
                    <button class="primary-color border rounded-3xl w-32 h-12  text-sm font-normal"> Start Free Trial</button>
                    <a href=""> <u> See all features </u></a>
                </div>
            </div>
        </div>
    </div>

    <!-- sign for trial -->

    <div class="flex justify-center items-center" style="height:700px">
        <div class="relative signInCard max-w-5xl w-full mx-auto flex flex-col items-center justify-center h-96 rounded-xl shadow-lg gap-4">
            <div class="text-white text-center">
                <h2 class="text-white">Signup for a trial</h2>
                <p class="text-sm"> Create your free account now and get 30 days free trial </p>
                <p class="text-sm"> No credit card required </p>
            </div>
            <div class="w-full max-w-lg mx-auto">
                <input type="text" class="p-2 rounded-full h-14 w-full px-6 border-0 placeholder-black font-light" placeholder="Your Email">

            </div>
            <div class="absolute w-full" style="opacity: 0.4;top:250px">
                <img src="../assets/14.png" alt="" class="w-full">
            </div>
        </div>
    </div>

    <!-- cards -->
    <div class="w-full py-10" style="background-color:#edf5ff;">
        <div class="flex justify-center flex-wrap gap-8 max-w-7xl w-full mx-auto py-4 ">
            <div class="textCard">
                <p class="backgroundpoligan"></p>
            </div>
            <div class="textCard">
                <p class="backgroundpoligan"></p>
            </div>
            <div class="textCard">
                <p class="backgroundpoligan"></p>
            </div>
            <div class="textCard">
                <p class="backgroundpoligan"></p>
            </div>
            <div class="textCard">
                <p class="backgroundpoligan"></p>
            </div>
            <div class="textCard">
                <p class="backgroundpoligan"></p>
            </div>

        </div>
    </div>
    <!-- Pricing -->
    <div id="pricing" class="container h-auto mx-auto max-w-7xl py-20">
        <div class="flex flex-col text-center w-full">
            <h1 class="sm:text-4xl text-3xl font-medium title-font mb-2 text-gray-900">Our Packages</h1>
            <p class="lg:w-2/3 mx-auto leading-relaxed text-base text-gray-600">Accelerate your career by adding value from future</p>
            <div class="flex mx-auto border-2 border-indigo-900 rounded overflow-hidden mt-6">
                <button @click="pro_amount=38;bus_amount=58;month_year='month'" class="py-1 px-4   focus:outline-none" :class="{'bg-primary text-white' :month_year==='month','bg-white text-black' :month_year==='annual'}">Monthly</button>
                <button @click="pro_amount=38*10;bus_amount=58*10;month_year='annual'" class="py-1 px-4 focus:outline-none " :class="{'bg-primary text-white' :month_year==='annual','bg-white text-black' :month_year==='annual'}">Annually</button>
            </div>
        </div>
        <div class="flex flex-wrap -m-4 content-center justify-center py-5">
            <div class="p-4 xl:w-2/6 md:w-1/2 w-full">
                <div class="h-full p-6 rounded-lg border-2 border-gray-300 flex flex-col relative overflow-hidden">
                    <h2 class="text-sm tracking-widest title-font mb-1 font-medium">START</h2>
                    <h1 class="text-5xl text-gray-900 pb-4 mb-4 border-b border-gray-200 leading-none">Free</h1>
                    <p class="flex items-center text-gray-600 mb-2">
                        <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                <path d="M20 6L9 17l-5-5"></path>
                            </svg>
                        </span>Public CV
                    </p>
                    <p class="flex items-center text-gray-600 mb-2">
                        <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                <path d="M20 6L9 17l-5-5"></path>
                            </svg>
                        </span>Code editors and task panes
                    </p>
                    <p class="flex items-center text-gray-600 mb-2">
                        <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                <path d="M20 6L9 17l-5-5"></path>
                            </svg>
                        </span>1000s of coding related articles
                    </p>
                    <p class="flex items-center text-gray-600 mb-6">
                        <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                <path d="M20 6L9 17l-5-5"></path>
                            </svg>
                        </span>100s of free quizes and code puzzles
                    </p>
                    <button class="flex items-center mt-auto text-white bg-gray-400 border-0 py-2 px-4 w-full focus:outline-none hover:bg-gray-500 rounded">Start
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-auto" viewBox="0 0 24 24">
                            <path d="M5 12h14M12 5l7 7-7 7"></path>
                        </svg>
                    </button>
                    <p class="text-xs text-gray-600 ml-1 mt-4">Suitable for students and absolute beginners</p>
                </div>
            </div>

            <div class="p-4 xl:w-2/6 md:w-1/2 w-full">
                <div class="h-full p-6 rounded-lg border-2 border-indigo-900 flex flex-col relative overflow-hidden">
                    <span class="bg-primary text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">POPULAR</span>
                    <h2 class="text-sm tracking-widest title-font mb-1 font-medium">PRO</h2>
                    <h1 v-if="month_year=='month'" class="text-5xl text-gray-900 leading-none flex items-center pb-4 mb-4 border-b border-gray-200">
                        <span>${{pro_amount}}</span>
                        <span class="text-lg ml-1 font-normal text-gray-500">/month</span>
                    </h1>
                    <h1 v-else class="text-5xl text-gray-900 leading-none flex items-center pb-4 mb-4 border-b border-gray-200">
                        <span>${{pro_amount}}</span>
                        <span class="text-lg ml-1 font-normal text-gray-500">/year</span>
                    </h1>
                    <p class="flex items-center text-gray-600 mb-2">
                        <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                <path d="M20 6L9 17l-5-5"></path>
                            </svg>
                        </span>Everything from <strong class="ml-2 text-pink-600"> Free</strong>
                    </p>
                    <p class="flex items-center text-gray-600 mb-2">
                        <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                <path d="M20 6L9 17l-5-5"></path>
                            </svg>
                        </span>Weekly live sessions
                    </p>
                    <p class="flex items-center text-gray-600 mb-2">
                        <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                <path d="M20 6L9 17l-5-5"></path>
                            </svg>
                        </span>Chat groups with instructors
                    </p>
                    <p class="flex items-center text-gray-600 mb-2">
                        <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                <path d="M20 6L9 17l-5-5"></path>
                            </svg>
                        </span>Certifications
                    </p>
                    <p class="flex items-center text-gray-600 mb-6">
                        <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                <path d="M20 6L9 17l-5-5"></path>
                            </svg>
                        </span>Git integeration and progress tracking
                    </p>
                    <button class="flex items-center mt-auto text-white bg-primary border-0 py-2 px-4 w-full focus:outline-none hover:bg-yellow-600 rounded">Singup
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-auto" viewBox="0 0 24 24">
                            <path d="M5 12h14M12 5l7 7-7 7"></path>
                        </svg>
                    </button>
                    <p class="text-xs ml-1 text-gray-600 mt-3">Best for job seekers</p>
                </div>
            </div>
            <div class="p-4 xl:w-2/6 md:w-1/2 w-full">
                <div class="h-full p-6 rounded-lg border-2 border-gray-300 flex flex-col relative overflow-hidden">
                    <h2 class="text-sm tracking-widest title-font mb-1 font-medium">BUSINESS</h2>
                    <h1 v-if="month_year=='month'" class="text-5xl text-gray-900 leading-none flex items-center pb-4 mb-4 border-b border-gray-200">
                        <span>${{bus_amount}}</span>
                        <span class="text-lg ml-1 font-normal text-gray-500">/month</span>
                    </h1>
                    <h1 v-else class="text-5xl text-gray-900 leading-none flex items-center pb-4 mb-4 border-b border-gray-200">
                        <span>${{bus_amount}}</span>
                        <span class="text-lg ml-1 font-normal text-gray-500">/year</span>
                    </h1>
                    <p class="flex items-center text-gray-600 mb-2">
                        <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                <path d="M20 6L9 17l-5-5"></path>
                            </svg>
                        </span>Everything from <strong class="ml-2 text-yellow-600"> Pro</strong>
                    </p>
                    <p class="flex items-center text-gray-600 mb-2">
                        <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                <path d="M20 6L9 17l-5-5"></path>
                            </svg>
                        </span>One-2-one mentoring
                    </p>
                    <p class="flex items-center text-gray-600 mb-2">
                        <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                <path d="M20 6L9 17l-5-5"></path>
                            </svg>
                        </span>Hands on workshops
                    </p>
                    <p class="flex items-center text-gray-600 mb-2">
                        <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                <path d="M20 6L9 17l-5-5"></path>
                            </svg>
                        </span>Create quizzes and taks panes
                    </p>
                    <p class="flex items-center text-gray-600 mb-6">
                        <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                <path d="M20 6L9 17l-5-5"></path>
                            </svg>
                        </span>Project level coaching
                    </p>
                    <button class="flex items-center mt-auto text-white bg-gray-400 border-0 py-2 px-4 w-full focus:outline-none hover:bg-gray-500 rounded">Start
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-auto" viewBox="0 0 24 24">
                            <path d="M5 12h14M12 5l7 7-7 7"></path>
                        </svg>
                    </button>
                    <p class="text-xs text-gray-500 mt-3">Engineers working in companies</p>
                </div>
            </div>
        </div>
    </div>

    <!-- Team -->
    <div class="w-full" style="height:700px">
        <div class="max-w-7xl w-full mx-auto bg-white flex flex-col items-center justify-center">
            <div class="text-center">
                <h2 class="mb-0">Innovative Team</h2>
                <div class="underLine">
                </div>
                <p class="max-w-2xl text-sm lg:text-base">
                    Outlived no dwelling denoting in peculiar as he believed. Behaviour excellent middleton be as it curiosity departure ourselves very extreme future.
                </p>
            </div>
            <div class="flex flex-wrap gap-4">
                <div class="infoCard"></div>
                <div class="infoCard"></div>
                <div class="infoCard"></div>

            </div>

        </div>
    </div>
</div>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
export default {
    components: {
        Navigation
    },

    data: () => {
        return {
            pro_amount: 38,
            bus_amount: 58,
            month_year: "month"

        }
    },

}
</script>

<style>
.banner {
    height: calc(-112px + 100vh);
    background: linear-gradient(90deg, #02ebad 2%, #5a57fb 109%);
    border-bottom-right-radius: 30%;
    border-bottom-left-radius: 0px;
}

.signInCard {
    background: linear-gradient(45deg, #02ebad 0%, #5a57fb 50%);

}

.backgroundpoligan::before {
    contain: "text";
    position: absolute;
    right: -35px;
    bottom: -85px;
    content: "";
    height: 80px;
    width: 80px;
    background: #62aac7;
    opacity: .3;
    z-index: 1;
    transform: translateX(-50%);
    -webkit-clip-path: polygon(40% 7.67949%, 43.1596% 6.20615%, 46.52704% 5.30384%, 50% 5%, 53.47296% 5.30384%, 56.8404% 6.20615%, 60% 7.67949%, 81.65064% 20.17949%, 84.50639% 22.17911%, 86.97152% 24.64425%, 88.97114% 27.5%, 90.44449% 30.6596%, 91.34679% 34.02704%, 91.65064% 37.5%, 91.65064% 62.5%, 91.34679% 65.97296%, 90.44449% 69.3404%, 88.97114% 72.5%, 86.97152% 75.35575%, 84.50639% 77.82089%, 81.65064% 79.82051%, 60% 92.32051%, 56.8404% 93.79385%, 53.47296% 94.69616%, 50% 95%, 46.52704% 94.69616%, 43.1596% 93.79385%, 40% 92.32051%, 18.34936% 79.82051%, 15.49361% 77.82089%, 13.02848% 75.35575%, 11.02886% 72.5%, 9.55551% 69.3404%, 8.65321% 65.97296%, 8.34936% 62.5%, 8.34936% 37.5%, 8.65321% 34.02704%, 9.55551% 30.6596%, 11.02886% 27.5%, 13.02848% 24.64425%, 15.49361% 22.17911%, 18.34936% 20.17949%);
    clip-path: polygon(40% 7.67949%, 43.1596% 6.20615%, 46.52704% 5.30384%, 50% 5%, 53.47296% 5.30384%, 56.8404% 6.20615%, 60% 7.67949%, 81.65064% 20.17949%, 84.50639% 22.17911%, 86.97152% 24.64425%, 88.97114% 27.5%, 90.44449% 30.6596%, 91.34679% 34.02704%, 91.65064% 37.5%, 91.65064% 62.5%, 91.34679% 65.97296%, 90.44449% 69.3404%, 88.97114% 72.5%, 86.97152% 75.35575%, 84.50639% 77.82089%, 81.65064% 79.82051%, 60% 92.32051%, 56.8404% 93.79385%, 53.47296% 94.69616%, 50% 95%, 46.52704% 94.69616%, 43.1596% 93.79385%, 40% 92.32051%, 18.34936% 79.82051%, 15.49361% 77.82089%, 13.02848% 75.35575%, 11.02886% 72.5%, 9.55551% 69.3404%, 8.65321% 65.97296%, 8.34936% 62.5%, 8.34936% 37.5%, 8.65321% 34.02704%, 9.55551% 30.6596%, 11.02886% 27.5%, 13.02848% 24.64425%, 15.49361% 22.17911%, 18.34936% 20.17949%);
}

.textCard {
    background-color: white;
    height: 320px;
    width: 380px;
    border: 1px solid lightblue;
    border-radius: 15px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5)
}

.textCard::after {
    color: lightgray;
    content: "\23";
    font-size: 58px;
    opacity: 0.6;
    position: absolute;
    right: 23px;
    top: 27px;
}

.underLine {
    display: inline-block;
    width: 50px;
    height: 2px;
    background: red;
    position: relative;
    z-index: 1;
    left: 10px;
}

.underLine::before {
    position: absolute;
    left: -15px;
    top: 0;
    content: "";
    height: 2px;
    width: 10px;
    background: red;
}

.infoCard {
    background-color: white;
    height: 320px;
    width: 380px;
    border: 1px solid lightblue;
    border-radius: 15px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5)
}
</style>
