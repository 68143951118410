<template>
<Navigation />

<div data-node-type="commerce-order-confirmation-wrapper" data-wf-order-query="" data-wf-page-link-href-prefix="" class="w-commerce-commerceorderconfirmationcontainer ">
    <div class=" mx-auto">
        <div class="flex items-center justify-center h-32 w-full  gap-4 container-default-1209px border shadow-lg card mb-4">
        <img src="https://img.icons8.com/flat-round/64/undefined/checkmark.png" />
        <p class="text-3xl">Thank You !</p>
    </div>
        <div class="w-commerce-commercelayoutcontainer container-default-1209px checkout w-container border py-4 card contact-us">
            <div class="w-commerce-commercelayoutmain top-content-checkout">
                <div class="w-commerce-commercecheckoutcustomerinfosummarywrapper">
                    <div class="w-commerce-commercecheckoutsummaryblockheader block-header">
                        <h4>Customer Information</h4>
                    </div>
                    <fieldset class="w-commerce-commercecheckoutblockcontent block-content">
                        <div class="w-commerce-commercecheckoutrow">
                            <div class="w-commerce-commercecheckoutcolumn">
                                <div class="w-commerce-commercecheckoutsummaryitem"><label class="w-commerce-commercecheckoutsummarylabel">User Name</label><label class="w-commerce-commercecheckoutsummarylabel">Email</label><label class="w-commerce-commercecheckoutsummarylabel">Mobile Number</label>
                                    <div></div>
                                </div>
                            </div>
                            <div v-if="courseDetail" class="w-commerce-commercecheckoutcolumn">
                                <div class="w-commerce-commercecheckoutsummaryitem "><label class="w-commerce-commercecheckoutsummarylabel ">{{this.userInfo.user.display_name}}</label><label class="w-commerce-commercecheckoutsummarylabel">{{courseDetail.data.email}}</label> <label class="w-commerce-commercecheckoutsummarylabel">{{courseDetail.data.contact}}</label>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div class="w-commerce-commercecheckoutsummaryflexboxdiv">
                                        <div class="w-commerce-commercecheckoutsummarytextspacingondiv"></div>
                                        <div class="w-commerce-commercecheckoutsummarytextspacingondiv"></div>
                                        <div class="w-commerce-commercecheckoutsummarytextspacingondiv"></div>
                                    </div>
                                    <div></div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div class="w-commerce-commercecheckoutshippingsummarywrapper">
                    <div class="w-commerce-commercecheckoutsummaryblockheader block-header">
                        <h4>Course Name</h4>
                    </div>
                    <fieldset class="w-commerce-commercecheckoutblockcontent block-content">
                        <div class="w-commerce-commercecheckoutrow">
                            <div class="w-commerce-commercecheckoutcolumn">
                                <div class="w-commerce-commercecheckoutsummaryitem">
                                    <div>{{this.courses_name}}</div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div class="w-commerce-commercecheckoutpaymentsummarywrapper">
                    <div class="w-commerce-commercecheckoutsummaryblockheader block-header">
                        <h4>Payment Info</h4>
                    </div>
                    <fieldset class="w-commerce-commercecheckoutblockcontent block-content">
                        <div class="w-commerce-commercecheckoutrow">
                            <div class="w-commerce-commercecheckoutcolumn">
                                <div class="w-commerce-commercecheckoutsummaryitem"><label class="w-commerce-commercecheckoutsummarylabel">Payment id</label>
                                    <div class="w-commerce-commercecheckoutsummaryflexboxdiv">
                                        <div class="w-commerce-commercecheckoutsummarytextspacingondiv"></div>
                                        <div class="w-commerce-commercecheckoutsummarytextspacingondiv"></div>
                                    </div>
                                    <div class="w-commerce-commercecheckoutsummaryflexboxdiv">
                                        <div></div>
                                        <div> {{this.razorpay_payment_id}} </div>
                                        <div></div>
                                    </div>
                                </div>
                            </div>
                            <div class="w-commerce-commercecheckoutcolumn">
                                <div class="w-commerce-commercecheckoutsummaryitem"><label class="w-commerce-commercecheckoutsummarylabel">Order id</label>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div class="w-commerce-commercecheckoutsummaryflexboxdiv">
                                        <div class="w-commerce-commercecheckoutsummarytextspacingondiv"></div>
                                        <div class="w-commerce-commercecheckoutsummarytextspacingondiv"></div>
                                        <div class="w-commerce-commercecheckoutsummarytextspacingondiv"></div>
                                    </div>
                                    <div>{{this.razorpay_order_id}}</div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div class="w-commerce-commercecheckoutorderitemswrapper">
                    <div class="w-commerce-commercecheckoutsummaryblockheader block-header">
                        <h4>Payment methods</h4>
                    </div>
                    <fieldset class="w-commerce-commercecheckoutblockcontent ">
                        <div v-if="courseDetail" class=" p-2 capitalize">{{courseDetail.data.method}}</div>

                    </fieldset>
                </div>
            </div>
            <div class="w-commerce-commercelayoutsidebar">
                <div class="w-commerce-commercecheckoutordersummarywrapper">
                    <div class="w-commerce-commercecheckoutsummaryblockheader block-header">
                        <h4>Order Summary</h4>
                    </div>
                    <fieldset class="w-commerce-commercecheckoutblockcontent block-content">
                        <div class="w-commerce-commercecheckoutsummarylineitem">
                            <div></div>
                            <div></div>
                        </div>
                        <div class="w-commerce-commercecheckoutordersummaryextraitemslist" data-wf-collection="database.commerceOrder.extraItems" data-wf-template-id="wf-template-5ef15a21c26c9147733e197800000000006b">
                            <div class="w-commerce-commercecheckoutordersummaryextraitemslistitem">
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                        <div class="w-commerce-commercecheckoutsummarylineitem">
                            <div class="bold-text">Total</div>
                            <div class="w-commerce-commercecheckoutsummarytotal bold-text">&#8377;{{this.amount}}</div>
                        </div>
                    </fieldset>
                </div>
                <div class="flex justify-center">
                    <router-link :to="{name:'Home'}">
                        <div class="border border-blue-500 bg-blue-700 text-white rounded-md p-2 hover:text-blue-700 hover:bg-white cursor-pointer">Back Home</div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
    
</div>
</template>

<script>
import axios from "axios";
import Navigation from "@/components/Navigation.vue";
import {
    mapGetters,
    mapActions
} from "vuex";
export default {
    props: ["razorpay_order_id", "razorpay_payment_id", "razorpay_signature", "courses_name", "amount", "courseId"],
    data: function () {
        return {
            courseDetail: null,
            payment_Detail: null,
        }

    },
    components: {
        Navigation
    },
    computed: mapGetters(["userInfo"]),
    methods: {
        ...mapActions(["subcription"]),
        async success(data) {
            await axios.get(`${process.env.VUE_APP_API}/checout/${data}/?format=json`)
                .then(res => {
                    this.courseDetail = res.data
                    this.price = new Intl.NumberFormat('hi-IN', {
                        style: 'currency',
                        currency: 'INR',
                        maximumFractionDigits: 0,
                    }).format(this.courseDetail.data.amount)

                })

        },
    },

    async created() {
        await this.success(this.razorpay_payment_id)
        await this.subcription(this.userInfo)
    }
}
</script>

<style>

</style>
