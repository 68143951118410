<template>
<div class="flex flex-col">
<Navigation active="dashboard" />
    <div class="h-12 mt-5">
        <nav class=" flex font-bold text-gray-700 items-center text-sm md:text-base justify-center ">
            <router-link :to="{name :'Dashboard'}" class="w-28 ml-5 p-1 inline-flex rounded items-center
            hover:border-2 hover:bg-teal-300 ">
                <BeakerIcon class="h-6 w-6 text-teal-700 mx-1 " />
                Home
            </router-link>
             
            <!-- <div class="typ-bn">
                <h1>
                    <span class="txt-rotate" data-period="2000" data-rotate="[ &quot;Enterprise Application Development&quot;, &quot;Cloud Engineering Services&quot;, &quot;Data Engineering &amp; Analytics&quot;]"><span class="wrap">Data Engineering &amp; Analytics</span></span>
                </h1>
            </div> -->
            <router-link v-if=" userInfo.user.permission == 'student' " :to="{name : 'UserProfile'}" @click="tab = 2" class="w-28 ml-5 p-1 inline-flex hover:bg-teal-300 rounded" >
                <AcademicCapIcon class="h-6 w-6 text-teal-700 mx-1" />
                Profile
            </router-link>

            <!-- <router-link :to="{name : 'DashboardSettings'}" class="w-28 ml-5 p-1 inline-flex items-center hover:bg-teal-300 rounded">
                <CogIcon class="h-6 w-6 text-teal-700 mx-1" />
                Settings
            </router-link> -->
            <div v-if="userInfo.user.permission == 'admin' || userInfo.user.permission == 'instructor'">
                <!-- Instructor Profile -->
            <router-link :to="{name:'instructorCourse'}" class="w-46 ml-5 p-1 inline-flex items-center hover:bg-teal-300 rounded">
                <CollectionIcon class="h-6 w-6 text-teal-700 mx-1" />
                <span>Profile</span>
            </router-link>
            </div>
            <div v-if="userInfo.userpermission == 'super_admin'">

            <router-link :to="{name:'organizationDashboard' ,params:{slug_id:userInfo.slug_id }}" class="w-32 ml-5 p-1 items-center inline-flex hover:bg-teal-300 rounded">
                <!-- <LibraryIcon class="h-6 w-6 text-teal-700 mx-1" /> -->
                <img src="../../assets/library.svg" alt="">
                <span>Organization</span>
            </router-link>
             
            </div>
            <!-- <router-link :to='{name :"sessionStudent"}' class="w-46 ml-5 p-1 inline-flex rounded
                hover:border-2 hover:bg-teal-300 ">
                    <BeakerIcon class="h-6 w-6 text-teal-700 mx-1 " />
                   Student Dashboard
                </router-link> -->
        </nav>
    </div>
</div>
<router-view :event="dashboard" />


</template>

<script>
// @ is an alias to /src
import Navigation from "@/components/Navigation.vue"
import {mapGetters} from "vuex";
import {
    BeakerIcon,
    AcademicCapIcon,
    CogIcon,
    CollectionIcon,
    LibraryIcon
} from '@heroicons/vue/outline';

export default {
    computed: mapGetters(["userInfo"]),
    name: "Home",
    components: {
        Navigation,
        BeakerIcon,
        AcademicCapIcon,
        CogIcon,
        CollectionIcon

    },
    data() {
        return {
            tab: 1,
        }
    },
    methods: {

    }
};
</script>
