<template>
    <Navigation />
<div class='overflow-y-auto h-screen w-full flex flex-row' style="scrollbar-color:gray white ">
    <internLaftNav actived='Mentership' />
    <div class="flex w-full flex-col">

        <div class="inline-block h-5/6 w-full p-0">
            <table class="leading-normal w-full text-md">
                <thead class="text-md text-gray-600 text-teal-700 font-normal">
                    <tr class="bg-gray-200 border-b-2 border-teal-700 text-md">
                        <th class="px-2 lg:px-5 py-2 items-center text-left tracking-wider">
                            Project Name <input type="search" placeholder="Search" v-model="searchvalue" class="w-2/4 text-base rounded-xl  border-teal-600 focus:ring-1  p-4 h-10 border mx-6" />
                            
                        </th>
                        <th class=" px-2 lg:px-5 py-2 text-left tracking-wider">
                            Job functions <i class="arrow down float-right text-yellow-600 mt-2"></i>
                        </th>
                        <th class="px-2 lg:px-5 py-2 text-left tracking-wider">
                            industry
                        </th>
                        <th class=" px-2 lg:px-5 py-2 text-left tracking-wider">
                            Students
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="project in filteredData" :key="project">
                        <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white ">
                            {{project.name}} 
                        </td>
                        <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white ">
                            {{project.job}} 
                        </td>
                        <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white ">
                            {{project.industry}}
                        </td>
                        <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white ">
                            <p class="text-center text-gray-900 whitespace-no-wrap">
                                {{project.students}}
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
   
</div>
</template>

<script>

import Navigation from "@/components/Navigation.vue"
import internLaftNav from "@/views/organization/leftNavIntern.vue"


export default {
    data() {
        return {
            searchvalue: "",
            datas:[{
                name:"Analyse User Behaviour and Optimise the User Worlflow",
                job:"Data Analytics",
                industry:"Technology",
                students:"--"
            },
            {
                name:"Studying music streaming patterns using data analytics",
                job:"Data Analytics",
                industry:"Ecommerce",
                students:"--"
            },
            {
                name:"Analysis of sales data of retail stores using exploratory and data visualization",
                job:"Data Analytics",
                industry:"Ecommerce",
                students:"--"
            },
            {
                name:"Covid19 articles extraction from various source",
                job:"Scraping",
                industry:"Data collection",
                students:"--"
            },
            {
                name:"Dashboard creation for covid19 impact",
                job:"Datacollection",
                industry:"Visualization",
                students:"-"
            },
            {
                name:"Online currency rate converter app",
                job:"API",
                industry:"Technology",
                students:"--"
            },
            {
                name:"E-commerce data collection",
                job:"Scraping",
                industry:"Data collection",
                students:"--"
            },
            {
                name:"E-commerce rating algorithm",
                job:"Rating",
                industry:"Ecommerce",
                students:"--"
            },
            {
                name:"E-commerce recommendation algorithm",
                job:"Recommendation",
                industry:"Ecommerce",
                students:"--"
            },
            {
                name:"Journal article website spider",
                job:"Scraping",
                industry:"Technology",
                students:"--"
            },
            {
                name:"Latest news scraping spider",
                job:"Scraping",
                industry:"Technology",
                students:"--"
            },
            {
                name:"Law and judgement data collection",
                job:"Data collcetion",
                industry:"Technology",
                students:"--"
            },
            {
                name:"Noticebard notification and announcement collecter",
                job:"Scraping",
                industry:"Technology",
                students:"--"
            },

            ]
            
        }
    },
    components: {
        Navigation,
        internLaftNav,
        
    },
    computed: {
        filteredData() {
            return this.datas.filter(data => {
                return data.name.toLowerCase().includes(this.searchvalue.toLowerCase())
            })
        }
    },
    
    
   

}
</script>

<style scoped>
.arrow {
    border: solid #eba60f;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
}

.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}
</style>
