<template lang="">
<div class="page-wrapper">
    <Navigation />
    <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />

    <div class="section hero wf-section">
        <div class="container-default-1209px w-container">
            <div class="flex flex-col">
                <div class="overflow-x-auto sm:-mx-6 lg:-mx-8 ">
                    <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8 ">
                        <div class="overflow-hidden border rounded-lg shadow-md ">
                            <table class="min-w-full">
                                <thead class="bg-white border-b">
                                    <tr class="">
                                        <th scope="col" class="text-base font-medium text-gray-900 px-6 py-4 text-left">
                                            #
                                        </th>
                                        <th scope="col" class="text-base font-medium text-gray-900 px-6 py-4 text-left">
                                            Name
                                        </th>
                                        <th scope="col" class="text-base font-medium text-gray-900 px-6 py-4 text-left">
                                            Email
                                        </th>
                                        <th scope="col" class="text-base font-medium text-gray-900 px-6 py-4 text-left">
                                            Phone No
                                        </th>
                                        <th scope="col" class="text-base font-medium text-gray-900 px-6 py-4 text-left">
                                            YOP
                                        </th>
                                        <th scope="col" class="text-base font-medium text-gray-900 px-6 py-4 text-left">
                                            Submited
                                        </th>
                                        <th scope="col" class="text-base font-medium text-gray-900 px-6 py-4 text-left flex items-center justify-center">
                                            Status
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(el,index) in  this.queryVal" :key="el" class="bg-gray-100 border-b">

                                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                            {{index+1}}
                                        </td>
                                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                            {{el.name}}
                                        </td>
                                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                            {{el.email}}
                                        </td>
                                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                            {{el.phone_number}}
                                        </td>
                                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                            {{el.year_of_passing}}
                                        </td>
                                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                            {{ new Date(el.submited).toLocaleDateString('en-IN', {day: 'numeric',month: 'long',year: 'numeric'}) }}
                                        </td>
                                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap flex items-center justify-center">
                                            <font-awesome-icon v-if="el.status" @click="previewComment(el.id)" class="text-xl text-green-600 self-center" :icon="['fas','check-square']" />
                                            <font-awesome-icon v-else @click="commentPopUp(el.id)" class="text-xl text-blue-600 self-center hover:text-green-900 " :icon="['fas','comment-dots']" />
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <!-- PopUp Comment-->
                        <div v-if="is_popUp" class="w-full h-full fixed top-0 left-0 bg-black bg-opacity-50 flex justify-center rounded-md items-center" style="z-index:1005">
                            <div class="relative mx-auto w-5/6 lg:w-2/3 2xl:w-1/3 h-96 ">
                                <img src="https://img.icons8.com/cotton/46/undefined/delete-sign--v2.png" @click="is_popUp=false" class="absolute top-2 -right-4  z-50 cursor-pointer" />
                                <form ref="commentReference" @submit.prevent="responseSend" class="h-full w-full flex items-center ">
                                    <div class="w-full bg-white card p-4 flex flex-col gap-2">
                                        <label for="comment">Comment</label>
                                        <textarea class="w-full h-auto" id="comment" rows="6" cols="50"></textarea>
                                        <div>
                                            <button type="submit" class="button-primary">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div v-if="is_Preview" class="w-full h-full fixed top-0 left-0 bg-black bg-opacity-50 flex justify-center rounded-md items-center" style="z-index:1000">
                            <div class="relative mx-auto w-11/12 lg:w-5/6 2xl:w-4/6 h-5/6 lg:h-4/6 2xl:h-2/3 " style="">
                                <img src="https://img.icons8.com/cotton/46/undefined/delete-sign--v2.png" @click="is_Preview=false" class="absolute -top-6 -right-6  z-50 cursor-pointer" />
                                <div  class="h-full w-full flex items-center ">
                                    <div class="w-full  bg-white  p-4 flex flex-col gap-1 h-full overflow-y-auto card1" style="scrollbar-color:white gray ">
                                        <div v-for="el in this.preview_data" :key="el" class="w-full h-full border px-2 py-1 rounded-xl ">
                                            <h5 class="capitalize py-1 mb-0">{{el.responded_person.display_name}} <span class="px-4 text-sm font-normal">@{{ new Date(el.responded_date).toLocaleDateString('en-IN', {day: 'numeric',month: 'long',year: 'numeric'}) }}</span></h5>
                                            <p class="text-sm"> {{el.responded_message}}</p>
                                        </div>
                                        <div class="m-2">
                                            <button @click="commentPopUp(this.preview_data[0].user_lead.id);" class="button-primary">Add More {{this.leadId}}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer />
</div>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";
import FlashMessage from "@/components/flashMessage.vue";
import {
    mapGetters
} from "vuex"
export default {
    data() {
        return {
            messages: {
                text: "",
                type: 'null',
            },
            queryVal: '',
            is_popUp: false,
            is_Preview: false,
            preview_data: '',
            leadId :''
        }
    },
    components: {
        Navigation,
        Footer,
        FlashMessage,
    },
    computed: mapGetters(["userInfo"]),

    created() {
        axios.get(`${process.env.VUE_APP_API}/user-lead/list/`)
            .then(response => {
                if (response.data.message == 'Have a User Lead details ') {
                    this.queryVal = response.data.data
                } else {
                    console.log('we dont have a data')
                }
            })
    },
    methods: {
        commentPopUp(el){
            this.is_popUp = true
            this.leadId = el
            console.log('el val',el)
        },
        previewComment(el) {
            console.log(el)
            axios.get(`${process.env.VUE_APP_API}/user-lead-response-fulldetails/list/?user_lead=${el}`)
                .then(response => {
                    this.preview_data = response.data.data
                    console.log(this.preview_data,'review')
                    this.is_Preview = true
                })

        },
        userLeadStatus(el) {
            let bodyVal = {
                "status": true
            }
            axios.patch(`${process.env.VUE_APP_API}/user-lead/deleted/${el}/`, bodyVal)
                .then(response => {

                    window.location.reload();
                })
        },
        responseSend(el) {
            console.log('response submit')
            let bodyVal = {
                "responded_message": el.target.comment.value,
                "user_lead": this.leadId,
                "responded_person": this.userInfo.user.id
            }

            console.log(bodyVal, 'Body Val')
            axios.post(`${process.env.VUE_APP_API}/user-lead-response/post/`, bodyVal)
                .then(response => {
                    console.log(response)
                    if (response.data.message == "Response added successfully") {
                        this.messages = {
                            text: "Comment Added successfully",
                            type: 'success',
                        }
                        setTimeout(() => {
                            this.messages = {
                                text: "",
                                type: 'null',
                            }
                            this.userLeadStatus(this.leadId)
                            this.$refs.commentReference.reset();
                            this.is_popUp = false

                        }, 1500)
                    } else {
                        this.messages = {
                            text: " Event Faild",
                            type: 'error',
                        }
                    }
                })
        }
    },
}
</script>

<style scoped>
tr:nth-child(even) {
    background-color: white;
}

</style>
