<template lang="">
<div>
    <Navigation />
    <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />
    <div v-if="this.webinar_val != ''" class="section event-top wf-section">
        <div class="container-default-1209px w-container">
            <div class="top-content event">
                <div data-w-id="5edbea58-8eeb-e0cc-7469-fb40feaf23c9" style="" class="split-content event-left">
                    <h1 class="title event">{{this.webinar_val[0].title}}</h1>
                    <p class="paragraph event">{{this.webinar_val[0].short_description}}</p>
                    <!-- <div v-if="this.is_register == false" class="button-primary large w-button">Register</div> -->
                </div>
                <div data-w-id="0d9e7d0b-09e8-2586-bbbf-1ed2b088c3d3" style="" class="animation-wrapper">
                    <div class="card event">
                        <div class="card-image-wrapper event">
                            <a href="contact-us.html" class="card-image-link event w-inline-block"><img :src="this.webinar_val[0].thumbnail" alt="" class="image event"></a>
                        </div>
                        <div class="card-content event">
                            <div class="event-details-wrapper">
                                <div class="event-details"><img src="../../catalyft-ui-templates/images/icon-event-02-academy-template.svg" alt="Clock Icon - Academy Webflow Template" class="event-icon">
                                    <div class="event-details-text _2">{{ new Date(this.webinar_val[0].event_date).toLocaleDateString('en-IN', {day: 'numeric',month: 'long',year: 'numeric'}) }}</div>
                                    <div class="event-details-text divider-text"> | </div>
                                    <div class="event-details-text _2">{{new Date(this.webinar_val[0].event_date).toLocaleTimeString('en-IN')}}</div>
                                </div>
                                <div class="spacer event-detail"></div>
                                <div class="event-details location"><img src="https://img.icons8.com/clouds/100/000000/guest-male.png" alt="Location Icon - Academy Webflow Template" class="h-10 w-10 rounded-full bg-blue-400">
                                    <div class="event-location-text _2 mx-2 capitalize mt-1">{{this.webinar_val[0].instructor_id.display_name}}</div>
                                </div>
                            </div>
                            <div v-if="this.is_register == false && is_loading != true" class="button-primary large full-width w-button" @click="registerWebinar()">Register</div>
                            <div v-else-if="this.is_register == false && is_loading == true" class="button-primary large full-width w-button">
                                <img class="mx-auto w-12 h-5" src="../assets/Pulse.gif" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="this.webinar_val != ''" data-w-id="ce6036be-3cd5-74cd-99bf-4e20bb213059" style="" class="section event wf-section">
        <div class="container-medium-810px w-container">
            <h2 data-w-id="d5399dfd-8d99-3649-8eb7-57161bf43daf" style="">About the Event</h2>
            <div data-w-id="41f81dc0-5aef-6fbf-eb60-942aaf21e31c" style="" class="rich-text w-richtext">
                <div class="max-w-4xl mx-auto py-4 bg-white ">
                    <v-md-preview :text="this.webinar_val[0].content"></v-md-preview>
                </div>
            </div>
        </div>
    </div>
    <div class="container-default-1209px w-container">
        <div data-w-id="f4e6ca73-e3f0-1664-e99b-0b1fcd5a7349" style="" class="divider"></div>
    </div>
    <div v-if="this.webinar_val != ''" data-w-id="68652dae-e8b7-02b9-6a86-4b271d7ff18f" style="" class="section events-section wf-section">
        <div class="container-default-1209px w-container">
            <div data-w-id="68652dae-e8b7-02b9-6a86-4b271d7ff191" style="" class="top-content latest-events">
                <div class="split-content events-section">
                    <h2 class="title latest-events">Latest Events</h2>
                </div>
                <router-link :to="{name : 'WebinarList'}" class="button-secondary w-button">All Events</router-link>
            </div>
            <div class="w-dyn-list">
                <div v-if="this.webinar_List != ''" role="list" class="events-grid w-dyn-items">
                    <div v-for="el in this.webinar_List" :key="el" data-w-id="55cd279d-65f1-1f8d-20fe-77100a429ba9" style="" role="listitem" class="w-dyn-item">
                        <div @click="forwardRouter(el.id)" class="card events">
                            <a href="#" class="w-full lg:w-3/6  flex items-center ">
                                <img :src="el.thumbnail" alt="" class="h-full w-full"></a>
                            <div class="card-content events w-full lg:w-3/6 ">
                                <a href="#" class="card-title-link w-inline-block">
                                    <h2 class="title card-event heading-h3-size">{{el.title}}</h2>
                                </a>
                                <p>{{el.short_description.slice(0,150)}} <span v-if="el.short_description.length>150">....</span></p>

                                <div class="divider card-events"></div>
                                <div class="card-event-details-wrapper">
                                    <div class="event-date-wrapper">
                                        <div class="event-date"><img src="../../catalyft-ui-templates/images/icon-event-01-academy-template.svg" alt="Calendar Icon - Academy Webflow Template" class="event-icon">
                                            <div class="event-details-text">{{ new Date(el.event_date).toLocaleDateString('en-IN', {day: 'numeric',month: 'long',year: 'numeric'}) }}</div>
                                        </div>
                                        <div class="event-time"><img src="../../catalyft-ui-templates/images/icon-event-02-academy-template.svg" alt="Clock Icon - Academy Webflow Template" class="event-icon">
                                            <div class="event-details-text">{{new Date(el.event_date).toLocaleTimeString('en-IN')}}</div>
                                        </div>
                                    </div>
                                    <div class="event-location-wrapper">
                                        <img src="https://img.icons8.com/clouds/100/000000/guest-male.png" alt="Location Icon - Academy Webflow Template" class="h-10 w-10 rounded-full bg-blue-400">
                                        <div class="event-location-text capitalize mt-1 px-2">{{el.instructor_id.display_name}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="card empty-state w-dyn-empty">
                    <div class="empty-state-text">There are no events available yet.</div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-default-1209px w-container">
        <div data-w-id="751bb4d3-ca3f-9ca7-4790-a8716f9f17d0" style="" class="divider"></div>
    </div>
    <Footer />
</div>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";
import FlashMessage from "@/components/flashMessage.vue";
import {
    mapGetters
} from "vuex"
export default {

    data() {
        return {
            webinar_val: '',
            webinar_List: '',
            messages: {
                text: "",
                type: 'null',
            },
            userList : '',
            is_register : true,
            is_loading : false
        }
    },
    components: {
        Navigation,
        Footer,
        FlashMessage,
        
    },
    props: ['webinar_id'],
    computed: mapGetters(["userInfo"]),
   async created() {
       await axios.get(`${process.env.VUE_APP_API}/webinar-details/list/?id=${this.webinar_id}`)
            .then(response => {

                if (response.data.message == 'No Webinar details available') {
                    this.webinar_val = ''
                } else {
                    this.webinar_val = response.data.data
                }
            })

       await axios.get(`${process.env.VUE_APP_API}/webinar-details/list/`)
            .then(response => {
                if (response.data.message == "Have a Webinar details") {
                    this.webinar_List = response.data.data
                    if (this.webinar_List.length >= 3) {
                        this.webinar_List = this.webinar_List.slice(0, 3)
                    } else {
                        this.webinar_List
                    }
                }
            })
        
       await axios.get(`${process.env.VUE_APP_API}/webinar-register/list/?webinar=${this.webinar_id}&email=${this.userInfo.user.email}`)
        .then (response => {
            
            if(response.data.message == 'No Webinar Register details available')
            {
                this.is_register = false
            }
            else {
                this.is_register = true
            }
            
            
        })
    },
    methods: {

        async forwardRouter(el) {
            await this.$router.push({
                name: 'WebinarRegister',
                params: {
                    webinar_id: el
                }
            })
            window.location.reload();
        },
        registerWebinar(){
            this.is_loading = true
            let headerVal = {
                
                    "email":this.userInfo.user.email ,
                    "webinar": parseInt(this.webinar_id)
            }
            axios.post(`${process.env.VUE_APP_API}/webinar-register/post/`,headerVal)
            .then(response => {

                if (response.data.message == "Webinar Registered successfully") {
                    this.messages = {
                        text: "Registered successfully",
                        type: 'success',
                    }
                    window.location.reload();
                } else {
                    this.messages = {
                        text: " Event Faild",
                        type: 'error',
                    }
                }
                setTimeout(() => {
                this.messages = {
                    text: "",
                    type: 'null',
                }
                this.is_loading = false
            }, 2000)
            })

        }

    }
}
</script>

<style lang="">
    
</style>
