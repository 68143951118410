<template>
<div class="min-h-screen hidden" style="display:none">
    <div id='yourCourse' class="bg-white p-0 md:p-8 md:px-24">
        <h2 class="text-gray-700 font-bold text-2xl text-center py-2"> You are currently learning</h2>
        <div class="flex flex-col justify-center items-center ">
            <div v-for="courses in allyourcourse" :key="courses" class=" flex flex-col bg-white rounded shadow w-11/12 lg:w-3/6 h-full m-1 border cursor-pointer" @click="infopagePush(courses.id)">
                <div class="flex flex-col md:flex-row gap-2 items-center p-1 h-full w-full  border-teal-400 ">
                    <img v-if=" courses.thumbnail" class="object-cover object-center h-24 w-52" :src="courses.thumbnail" alt="" />
                    <img v-else class="object-cover object-center bg-teal-400 h-24 w-52" src="../../assets/coding.svg" alt="" />
                    <div class="flex justify-between items-center w-full">
                        <div class="w-full lg:w-2/3">
                            <h1 class="text-gray-700 text-base font-bold hover:text-teal-600">{{courses.title}}</h1>
                            <h3 class="text-left text-sm px-1 py-1 text-red-500 font-medium">
                                By {{courses.instructor}}
                            </h3>
                            <p class='text-sm px-1 '>{{courses.short_description}}</P>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id='WelcomeInfo' class='flex  justify-center hidden'>
        <div class="flex flex-col items-center ">
            <img src="../../assets/SearchCourses.svg" alt="" class="h-3/6">
            <router-link to="/courses">
                <button class="mt-4 lg:mt-8 inline-flex text-white py-2 px-6 bg-teal-700 hover:bg-teal-900 shadow-md rounded text-lg">
                    Purchases your courses
                </button>
            </router-link>
        </div>
    </div>
    <!-- new courses -->
    <!-- <h2 class="text-gray-700 font-bold text-2xl text-center py-4"> New Arrival</h2>
    <div class="bg-white  flex justify-center items-center relative">          
        <div id="courseLists" class="flex  flex-col  md:flex-row overflow-x-scroll w-full md:w-5/6  items-center  " style="scrollbar-width:none;scroll-behavior: smooth;">            
            <div v-for="course in courseDetails" :key="course" >
                <router-link :to="{name :'CouseDetail',params:{slug:course.slug, title:course.title}}" class=" flex flex-col bg-white rounded shadow h-64 w-11/12 md:h-64 md:w-96 m-1 border  relative ">
                <div class="flex h-64 w-full md:w-96 justify-center ">
                    <img v-if='course.thumbnail==null' class=" object-cover object-center" src="../../assets/coding.svg" alt="" />
                    <img v-else class=" object-center object-cover" :src="course.thumbnail" alt="" />
                </div>
                <div class="flex bg-gray-700 h-20 w-full md:w-96 justify-center items-center ">
                    <p class="text-gray-100 text-sm md:text-md   cursor-pointer text-ellipsis md:truncate" :title="course.title">{{course.title}}</p>
                </div>
                <div class="absolute text-white font-bold text-2xl px-2 top-0 left-0 bg-gray-700">
                    &#36;{{course.price}}
                </div>
                </router-link>
            </div>  
            <div v-if="ScrollLimt > 0" @click="moveRight()"  class="hidden absolute bg-gray-300 h-12 p-1  md:flex items-center rounded-full " style="left: 7%;">
             
                <img src="../../assets/leftArrow.svg" alt="">
        </div>
        <div v-if="ScrollLimt <= maxScroll" @click="moveLeft()"  class="hidden absolute bg-gray-300 h-12 p-1  md:flex items-center rounded-full z-50 " style="right: 7%;">
            
            <img src="../../assets/rightArrow.svg" alt="">
            </div>          
        </div>Cources
            
            
    </div> -->
</div>

<!-- Course Details And Discussion page -->
<div class="h-screen bg-gray-50 flex hidden">
    <LeftNavigation />
    <div class="flex flex-col p-8 max-w-7xl shadow-lg">
        <div class="w-full h-full flex flex-col gap-4">
            <div class="flex h-1/2 flex-wrap gap-8 w-full justify-around">
                <div v-for="courses in allyourcourse" :key="courses" class="w-80 h-48 bg-white p-4 flex flex-col justify-between shadow-lg rounded">
                    <div class="flex flex-col gap-2">
                        <h1 class="font-semibold">{{courses.title}}</h1>
                        <p class="text-gray-600 text-sm">
                            {{courses.short_description}}
                            <span class="text-teal-600 px-2">
                                {{courses.instructor}}</span>
                        </p>
                    </div>
                    <!-- <h1>{{courses}} </h1>  -->
                    <div>
                        <div class="bg-white rounded border border-gray-300">
                            <div class="h-2 bg-gradient-to-l from-yellow-100 via-green-400 to-yellow-700 rounded" style="width:55%;"></div>
                        </div>
                        <small class="font-thin px-2 "> <span class="text-pink-600 font-medium"> 55% </span> complete</small>
                    </div>
                </div>
            </div>
            <!-- discussion -->
            <div class=" flex flex-col  h-1/2 p-8">
                <div class=" flex items-center text-center w-full  bg-white rounded" style="height:70px">
                    <div @click="is_selectedcourse='discussion'" class="w-1/3 bg-white text-2xl h-full flex justify-center items-center" :class="is_selectedcourse=='discussion' ? 'border-t-4 border-teal-500 text-teal-600 text-2xl font-bold' : ' border bg-gray-100' ">Discussion</div>
                    <div @click="is_selectedcourse='announcement'" class="w-1/3 bg-white text-2xl h-full flex justify-center items-center" :class="is_selectedcourse=='announcement' ? 'border-t-4 border-teal-500 text-teal-600 text-2xl font-bold' : ' border bg-gray-100' ">Announcement</div>
                    <div @click="is_selectedcourse='student'" class="w-1/3 bg-white text-2xl h-full flex justify-center items-center" :class="is_selectedcourse=='student' ? 'border-t-4 border-teal-500 text-teal-600 text-2xl font-bold' : ' border bg-gray-100' ">Student</div>
                </div>
                <div class="bg-white w-full  p-8  overflow-y-auto" style="scrollbar-color:gray white">
                    <div class="">
                        <textarea name="post" id="post" cols="30" rows="4" class="w-full" placeholder="add the discussion. Direct a comment to an individual by typeing @Name"></textarea>
                        <button class="border border-teal-500 px-4 py-1 rounded float-right">Post</button>
                    </div>
                    <div v-for="i in [1,2,3,4,5]" :key="i" class="p-4 border-b">
                        <div class="flex items-center gap-2 w-full">
                            <img src="https://static.vecteezy.com/system/resources/previews/006/017/690/original/ui-profile-icon-vector.jpg" alt="" class="w-8 h-8">
                            <div class="text-teal-600 font-semibold flex gap-2 w-full items-center">
                                <h1>Jhon</h1>
                                <p class="text-sm text-gray-600 font-normal w-40">Posted a discussion</p>
                                <div class="text-sm text-gray-600 flex gap-1 w-full items-center">
                                    <img src="../../assets/clock.svg" class="w-6" alt="">
                                    <span> 3 month ago </span>
                                </div>
                            </div>
                        </div>
                        <p class="px-2 text-gray-600">
                            how do i remove this course. nothing against it i just complet course on this program now ?
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Student Dashboard Details Page -->
<div class="min-h-screen  flex bg-gray-100 ">
    <!-- <LeftNavigation /> -->
    <!-- Main -->
    <div class="w-full h-full p-8 max-w-7xl shadow-lg bg-gray-100 mt-4 mx-auto">
        <div class="w-full flex flex-col h-full gap-4">
            <!-- Head -->
            <div class="head-temp flex justify-between gap-4 ">
                <div class="flex items-center gap-4 border border-gray-100 p-4 w-1/3 bg-white rounded ">
                    <img src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/54/000000/external-events-security-guard-flaticons-lineal-color-flat-icons.png" />
                    <div class="mx-auto">
                        <div class="text-4xl font-semibold text-teal-600">{{this.eventCount}}</div>
                        <div>Courses Events</div>
                    </div>
                </div>
                <div class="flex items-center gap-4 border border-gray-100 p-4 w-1/3 bg-white rounded ">
                    <img src="https://img.icons8.com/external-flaticons-flat-flat-icons/64/000000/external-deadline-agile-flaticons-flat-flat-icons-2.png" />
                    <div class="mx-auto">
                        <div class="text-4xl font-semibold text-teal-600">0</div>
                        <div>OverDue Courses</div>
                    </div>
                </div>
                <div class="flex items-center gap-4 border border-gray-100 p-4 w-1/3 bg-white rounded ">
                    <img src="https://img.icons8.com/bubbles/64/000000/certificate.png" />
                    <div class="mx-auto">
                        <div class="text-4xl font-semibold text-teal-600">0</div>
                        <div>Completed courses</div>
                    </div>
                </div>
            </div>

            <!-- Body -->
            <div class="body-temp flex items-center justify-around gap-4 ">

                <div v-if="is_reward" class="w-1/2 px-4 py-4 flex items-center gap-2 border border-gray-100 h-full bg-white rounded-l ">
                    <div class="w-1/2 flex flex-col gap-2">
                        <div class="flex flex-col">
                            <h1 class="text-2xl font-semibold text-teal-600">Reward</h1>
                            <p class="text-sm">Your latest achievements</p>
                        </div>
                        <button class="border px-2 rounded-md w-1/2 border-teal-300">View All</button>
                    </div>
                    <div class="w-2/3 flex">
                        <img src="https://img.icons8.com/external-flaticons-flat-flat-icons/32/000000/external-rewards-home-based-business-flaticons-flat-flat-icons-2.png" />
                        <img src="https://img.icons8.com/external-flaticons-flat-flat-icons/32/000000/external-rewards-home-based-business-flaticons-flat-flat-icons-2.png" />
                        <img src="https://img.icons8.com/external-flaticons-flat-flat-icons/32/000000/external-rewards-home-based-business-flaticons-flat-flat-icons-2.png" />
                        <img src="https://img.icons8.com/external-flaticons-flat-flat-icons/32/000000/external-rewards-home-based-business-flaticons-flat-flat-icons-2.png" />
                        <img src="https://img.icons8.com/external-flaticons-flat-flat-icons/32/000000/external-rewards-home-based-business-flaticons-flat-flat-icons-2.png" />
                    </div>
                </div>
                <div v-else class="w-1/2 px-4 py-4 flex items-center gap-2 border border-gray-100 h-full bg-white rounded-l ">
                    <div class="w-1/2 flex flex-col gap-2">
                        <div class="flex flex-col">
                            <h1 class="text-2xl font-semibold text-teal-600">Rewards</h1>
                            <p class="text-sm">Play the Quiz ang get the reward</p>
                        </div>
                        <a href="">

                        <!-- <button class="border px-2 rounded-md w-1/2 border-teal-300">Play</button> -->
                        </a>
                    </div>
                    <div class="w-2/3 flex">
                        <img src="https://img.icons8.com/external-icongeek26-linear-colour-icongeek26/64/000000/external-reward-crowdfunding-icongeek26-linear-colour-icongeek26.png" />

                    </div>
                </div>
                <div v-if="is_certificate" class="w-1/2 px-4 py-4 flex items-center gap-2 border border-gray-100 h-full bg-white rounded-r ">
                    <div class="w-1/2 flex flex-col gap-2">
                        <div>
                            <h1 class="text-2xl font-semibold text-teal-600">Certificates</h1>
                            <p class="text-sm">Your latest certificates</p>
                        </div>
                        <button class="border px-2 rounded-md w-1/2 border-teal-300">View All</button>
                    </div>
                    <div class="w-1/2 flex">
                        <img src="https://img.icons8.com/plasticine/64/000000/certificate.png" />
                        <img src="https://img.icons8.com/plasticine/64/000000/certificate.png" />
                        <img src="https://img.icons8.com/plasticine/64/000000/certificate.png" />

                    </div>
                </div>
                <div v-else class="w-1/2 px-4 py-4 flex items-center gap-2 border border-gray-100 h-full bg-white rounded-r ">
                    <div class="w-1/2 flex flex-col gap-2">
                        <div>
                            <h1 class="text-2xl font-semibold text-teal-600">Certificates</h1>
                            <p class="text-sm"> Let Continue you Learning </p>
                        </div>
                        <!-- <button class="border px-2 rounded-md w-1/2 border-teal-300">Start</button> -->
                    </div>
                    <div class="w-1/2 flex">
                        <img src="https://img.icons8.com/plasticine/64/000000/certificate.png" />
                        <img src="https://img.icons8.com/plasticine/64/000000/certificate.png" />
                        <img src="https://img.icons8.com/plasticine/64/000000/certificate.png" />

                    </div>
                </div>
            </div>
            <!-- footer -->
            <div class="footer-temp p-0 " style="scrollbar-color:#E5E7EB white">

                <div class="h-full flex items-center text-center w-full  bg-white rounded " style="height:6%;max-height:70px">
                    <div @click="is_selected='course'" class="w-1/3 bg-white text-2xl h-full flex justify-center items-center cursor-pointer" :class="is_selected=='course' ? 'border-t-4 border-teal-800 text-gray-700 text-2xl font-bold' : 'border bg-gray-100' ">Courses</div>
                    <div @click="is_selected='quizzes'" class="w-1/3 bg-white text-2xl h-full flex justify-center items-center cursor-pointer" :class="is_selected=='quizzes' ? 'border-t-4 border-teal-800 text-gray-700 text-2xl font-bold' : 'border bg-gray-100' ">Quizzes</div>
                    <div @click="is_selected='form'" class="w-1/3 bg-white text-2xl h-full flex justify-center items-center cursor-pointer" :class="is_selected=='form' ? 'border-t-4 border-teal-800 text-gray-700 text-2xl font-bold' : 'border bg-gray-100' ">News</div>
                </div>

                <div v-if="is_selected=='course'" class="bg-white w-full p-0 md:p-8 overflow-y-auto" style="scrollbar-color:#E5E7EB white;height:94%">
                    <h2 class="text-gray-700  text-2xl text-center py-2"> You are currently learning</h2>
                    <div class="flex flex-col  items-center h-full w-full">
                        <div v-for="courses in allyourcourse" :key="courses" class=" flex flex-col bg-white w-5/6 h-36 m-1 cursor-pointer relative border-b" @click="infopagePush(courses.id)">
                            <div class="flex flex-col md:flex-row gap-2 items-center p-1 h-full w-full ">
                                <img v-if=" courses.thumbnail" class="object-cover object-center h-24 w-52" :src="courses.thumbnail" alt="" />
                                <img v-else class="object-cover object-center bg-teal-400 h-24 w-52" src="../../assets/coding.svg" alt="" />
                                <div class="flex justify-between items-center w-5/6 bg-white">
                                    <div class="w-full lg:w-2/3">
                                        <h1 class="text-gray-700 text-base  hover:text-teal-600">{{courses.title}}</h1>
                                        <h3 class="text-left text-sm px-1 py-1 text-red-500 font-medium">
                                            By {{courses.instructor}}
                                        </h3>
                                        <p class='text-sm px-1 '>{{courses.short_description}}</P>
                                    </div>
                                </div>
                            </div>
                            <!-- <button class="absolute right-4 top-1/2 px-2 py-1 border rounded-lg border-teal-500 text-sm">Continue</button> -->
                        </div>
                    </div>
                </div>

                <!--General Quiz List Show -->
                <div v-else-if="is_selected=='quizzes'" class="bg-white w-full p-0 md:p-8 overflow-y-auto" style="scrollbar-color:#E5E7EB white;height:94%">
                    <h2 class="text-gray-700  text-2xl text-center py-2"> General Quiz List</h2>
                    <div class="flex flex-col  items-center h-full w-full">
                        <div v-for="quiz in generalquiz" :key="quiz" class=" flex flex-col bg-white w-5/6 h-36 m-1 cursor-pointer relative border-b">
                            <router-link :to="{name : 'Quizzes',params:{quizId:quiz.id}}" class="flex flex-col md:flex-row gap-2 items-center p-1 h-40 w-full">
                                <img v-if=" quiz.image" class="object-cover object-center h-24 w-52" :src="quiz.image" alt="" />
                                <img v-else class="object-cover object-center bg-teal-400 h-24 w-52" src="../../assets/coding.svg" alt="" />
                                <div class="flex justify-between items-center w-5/6 ">
                                    <div class="w-full ">
                                        <h1 class="text-gray-700 text-base font-bold hover:text-teal-600">{{quiz.title}}</h1>
                                        <h3 class="text-left text-sm px-1 py-1 text-red-500 font-medium">
                                            {{quiz.question_count}} Questions
                                        </h3>
                                        <p class='text-sm px-1'>{{quiz.description}}</p>
                                    </div>
                                </div>
                            </router-link>
                            <!-- <button class="absolute right-4 top-1/2 px-2 py-1 border rounded-lg border-teal-500 text-sm">Continue</button> -->
                        </div>
                    </div>
                </div>

                <!--News List Show -->
                <div v-else class="bg-white w-full p-0 md:p-8 overflow-y-auto" style="scrollbar-color:#E5E7EB white;height:94%">
                    <h2 class="text-gray-700  text-2xl text-center py-2"> Post</h2>
                    <div class="flex flex-col  items-center h-full w-full">
                        <h1 class="text-2xl text-gray-800">No content available</h1> 
                    </div>
                </div>

            </div>
        </div>

    </div>

</div>
</template>

<script>
import axios from "axios"
import {
    mapGetters,
    mapActions
} from "vuex";
import LeftNavigation from "@/components/LeftNavigation.vue"
export default {
    data() {
        return {
            courseDetails: null,
            ScrollLimt: 0,
            maxScroll: 0,
            boughtcourse: '',
            listOfcourse: [],
            allyourcourse: "",
            courseInfo: "",
            is_selected: "course",
            is_selectedcourse: "discussion",
            is_reward: false,
            is_certificate: false,
            eventCount: 0,
            is_detailsPage: false,
            generalquiz: null,
        }
    },
    components: {
        LeftNavigation
    },
    computed: mapGetters(["userInfo"]),

    async mounted() {

        axios.get(`${process.env.VUE_APP_API}/quiz_list/?Key=${this.userInfo.token_key}`)
            .then(res => {
                console.log(res.data, "response")
                this.generalquiz = res.data.data
                console.log(this.generalquiz, "quiz")
            })

        await axios.get(`${process.env.VUE_APP_API}/courses/?format=json`)
            .then(response => this.courseDetails = response.data.data)

        await axios.get(`${process.env.VUE_APP_API}/studentsbuycoursedetails/?Key=${this.userInfo.token_key}`)
            .then(response => {
                this.boughtcourse = response.data

                if (this.boughtcourse.data.length > 0) {
                    document.querySelector('#WelcomeInfo').classList.add('hidden')
                } else {
                    document.querySelector('#WelcomeInfo').classList.toggle('hidden')
                }
            })

        for (let el in this.boughtcourse.data) {
            this.listOfcourse.push(this.boughtcourse.data[el].course_id)
        }
        this.allyourcourse = this.courseDetails.filter(el => (this.listOfcourse).includes(el.id))

        this.getSubinfo()

        this.geteventinfo()
    },

    methods: {
        ...mapActions(["subcription"]),

        geteventinfo() {
            axios.get(`${process.env.VUE_APP_API}/calendar/view/?Key=${this.userInfo.token_key}`)
                .then(response => {
                    if (response.data.data.length > 0) {
                        this.eventCount = response.data.data.length
                    }
                })
        },

        getSubinfo() {
            this.subcription(this.userInfo);

        },

        async infopagePush(data) {
            this.boughtcourse = this.boughtcourse.data
            this.boughtcourse = this.boughtcourse.filter(el => (data == el.course_id))

            this.$router.push({
                name: "sessionStudent",
                params: {
                    slug: this.userInfo.slug_id,
                    course_id: this.boughtcourse[0].course_id,
                    instructor_id: this.boughtcourse[0].instructor_id,
                    course_name: this.boughtcourse[0].title,
                }
            })
        },

        moveLeft() {
            this.maxScroll = document.getElementById('courseLists').scrollLeftMax
            this.ScrollLimt = document.getElementById('courseLists').scrollLeft += 782;
            document.getElementById('courseLists').scrollLeft += 782;

        },

        moveRight() {
            document.getElementById('courseLists').scrollLeft -= 782;
            this.ScrollLimt = document.getElementById('courseLists').scrollLeft -= 782;
        }

    }
}
</script>

<style scoped>
.head-temp {
    height: 15vh;
    max-height: 96px;
}

.body-temp {
    height: 15vh;
    max-height: 120px;
}

.footer-temp {
    height: 70vh;

}

.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
